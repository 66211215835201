import { Row } from "@tanstack/react-table";
import { IProduct } from "integrations/firebase/collections";
import { getBadgeStatusFromRow } from "./utils";
import { GenerationStatusBadge } from "./GenerationStatusBadge";

interface TableStatusBadgesProps {
  row: Row<IProduct>;
}
export const TableStatusBadges = ({ row }: TableStatusBadgesProps) => {
  const { keywordsStatus, descriptionStatus, subjectsStatus } =
    getBadgeStatusFromRow(row);
  const {
    original: { id },
  } = row;
  return (
    <div className="flex items-center justify-center gap-x-1">
      <GenerationStatusBadge labelKey="keywords" status={keywordsStatus} />
      <GenerationStatusBadge
        labelKey="subjects"
        productId={id}
        status={subjectsStatus}
      />
      <GenerationStatusBadge
        labelKey="description"
        status={descriptionStatus}
      />
    </div>
  );
};
