import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { getUsersCollection, IUser } from "integrations/firebase/collections";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { orderBy, query, where } from "firebase/firestore";
import { z } from "zod";

import { OrganisationUserCreateRequest } from "__generated__/api/types.gen";
import {
  FirestoreOrganisation,
  UserRole,
} from "__generated__/models/types.gen";
import { useOrganisations } from "./OrganisationsProvider";
import { useApi } from "../hooks/useApi";
import { accountOrganisationFilesResponseSchema } from "../__generated__/api/validation.gen";

interface IOrganisationContext {
  organisation?: FirestoreOrganisation;
  members: IUser[];
  loading: boolean;

  addUsers(users: OrganisationUserCreateRequest[]): Promise<void>;

  removeUser(userId: string): Promise<void>;

  addFile(file: File): Promise<void>;

  getFiles(): Promise<
    z.infer<typeof accountOrganisationFilesResponseSchema> | undefined
  >;

  updateUserRole(userId: string, role: UserRole): Promise<void>;
}

export const OrganisationContext = createContext<IOrganisationContext>(
  undefined as never,
);

interface OrganisationProviderProps {
  id: string;
}

export const OrganisationProvider = ({
  id,
  children,
}: PropsWithChildren<OrganisationProviderProps>) => {
  const { organisations, loading } = useOrganisations();

  const api = useApi();

  const organisation = useMemo(
    () => organisations.find((o) => o.id === id),
    [organisations, id],
  );

  const [members = []] = useCollectionData(
    query(
      getUsersCollection(),
      where("organisation.id", "==", id),
      orderBy("email"),
    ),
  );

  const addFile = useCallback(
    async (file: File) => {
      if (!organisation) {
        return;
      }
      await api.account.organisations.addFile(organisation.id, file);
    },
    [organisation, api],
  );

  const getFiles = useCallback(async () => {
    if (!organisation) {
      return;
    }
    return api.account.organisations.getFiles(organisation.id);
  }, [organisation, api]);

  const addUsers = useCallback(
    async (users: OrganisationUserCreateRequest[]) => {
      if (!organisation) {
        return;
      }

      await api.account.organisations.addUsers(organisation.id, {
        users,
      });
    },
    [organisation, api],
  );

  const removeUser = useCallback(
    async (userId: string) => {
      if (!organisation) {
        return;
      }

      await api.account.organisations.removeUser(organisation.id, userId);
    },
    [organisation, api],
  );

  const updateUserRole = useCallback(
    async (userId: string, role: UserRole) => {
      if (!organisation) {
        return;
      }

      await api.account.organisations.updateUserRole(
        organisation.id,
        userId,
        role,
      );
    },
    [organisation, api],
  );

  const value = useMemo(
    () => ({
      members,
      organisation,
      loading,
      addUsers,
      removeUser,
      addFile,
      getFiles,
      updateUserRole,
    }),
    [
      members,
      organisation,
      loading,
      addUsers,
      removeUser,
      addFile,
      getFiles,
      updateUserRole,
    ],
  );

  return (
    <OrganisationContext.Provider value={value}>
      {children}
    </OrganisationContext.Provider>
  );
};

export const useOrganisation = () => {
  const context = useContext(OrganisationContext);

  if (!context) {
    throw new Error(
      "useOrganisation must be used within a OrganisationProvider",
    );
  }

  return context;
};
