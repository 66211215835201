import { entries } from "lodash";
import { UAParser } from "ua-parser-js";

export enum CustomFields {
  CATEGORY_FORM_ID = 16161398736668,
  ISBN_FORM_ID = 16164494549020,
  COMPANY_FORM_ID = 16161392708508,
}

export enum TicketCategory {
  question = "question",
  access_issue = "access_issue",
  feature_request = "feature_request",
  bug = "bug",
  feedback = "feedback",
  other = "other",
}

type Metadata = Record<string, string | number | undefined>;

interface Ticket {
  subject?: string;
  name: string;
  email: string;
  message: string;
  isbn?: string;
  category: TicketCategory;
  metadata?: Metadata;
}

function getDefaultMetadata() {
  try {
    const metadata: Array<[string, string | undefined]> = [];

    const parser = new UAParser();
    const results = parser.getResult();

    metadata.push(
      ["Browser", `${results.browser.name} (${results.browser.version})`],
      ["OS", `${results.os.name} (${results.os.version})`],
      ["Device", `${results.device.model} (${results.device.vendor})`],
    );

    return metadata;
  } catch (e) {
    console.error("Failed to get default metadata", e);

    return [];
  }
}

function createBody(message: string, metadata: Metadata) {
  let body = message;

  const data = [...entries(metadata), ...getDefaultMetadata()];

  body += "\n\n----------\n";

  // eslint-disable-next-line no-restricted-syntax
  for (const [property, value] of data) {
    if (!value) {
      // eslint-disable-next-line no-continue
      continue;
    }

    body += `**${property}**: ${value}\n`;
  }

  return body;
}

export async function createTicket(ticket: Ticket) {
  const { subject, name, email, message, category, metadata = {} } = ticket;

  const body = createBody(message, metadata);

  const request = {
    subject: subject || message.substring(0, 100),
    requester: {
      name: name.trim(),
      email: email.trim(),
    },
    comment: {
      body: body.trim(),
    },
    custom_fields: [
      {
        id: CustomFields.CATEGORY_FORM_ID,
        value: `${category.trim()}_reedy`,
      },
    ],
  };

  if (metadata.isbn) {
    request.custom_fields.push({
      id: CustomFields.ISBN_FORM_ID,
      value: metadata.isbn.toString(),
    });
  }

  if (metadata.organisation) {
    request.custom_fields.push({
      id: CustomFields.COMPANY_FORM_ID,
      value: `${metadata.organisation.toString().replace(/-/g, "_").toLowerCase()}_clone`,
    });
  }

  const response = await fetch(import.meta.env.VITE_ZENDESK_REQUESTS_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ request }),
  });

  try {
    const data = await response.json();
    return { status: response.status, data };
  } catch (error) {
    return { status: response.status, data: response.statusText };
  }
}
