import { FirestoreProductSubject } from "__generated__/models";
import { Model } from "integrations/firebase/collections/model";
import { EmptyListProps } from "components/EmptyList/EmptyListTypes";

export interface ThemaTabProps {
  title: string;
  key: "generated" | "database";
  content: React.ReactNode;
}

export enum SubjectSchemeIdentifier {
  ThemaSubjectCategory = "themaSubjectCategory",
  ThemaPlaceQualifier = "themaPlaceQualifier",
  ThemaTimePeriodQualifier = "themaTimePeriodQualifier",
  ThemaStyleQualifier = "themaStyleQualifier",
  ThemaEducationalPurposeQualifier = "themaEducationalPurposeQualifier",
  ThemaInterestAgeSpecialInterestQualifier = "themaInterestAgeSpecialInterestQualifier",
}

export type ThemaItem = Model<FirestoreProductSubject> & {
  subjectSchemeIdentifier: SubjectSchemeIdentifier;
};

export type ListVariant = "horizontal" | "vertical";

export interface BaseListContentProps<T> {
  isEmptyList: boolean;
  items: T[];
  finalItems: T[];
  variant: ListVariant;
  emptyListProps: Partial<EmptyListProps>;
  itemTestIdPrefix: string;
}
