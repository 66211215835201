import { GridContent } from "components/ui/grid";
import { EmptyList } from "components/EmptyList";
import { FirestoreProductSubject } from "__generated__/models";
import { BaseListContentProps } from "pages/ProductDetails/tabs/thema/types";
import { SubjectItem } from "../../pages/ProductDetails/tabs/thema/SubjectItem";

export const BaseListContent = <T extends FirestoreProductSubject>({
  isEmptyList,
  items,
  finalItems,
  variant,
  emptyListProps,
  itemTestIdPrefix,
}: BaseListContentProps<T>) => {
  if (isEmptyList) {
    return (
      <EmptyList
        variant={variant}
        {...emptyListProps}
        title={emptyListProps.title || ""}
        description={emptyListProps.description || ""}
      />
    );
  }
  return (
    <GridContent className="md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
      {items.map((item) => (
        <SubjectItem
          key={item.subjectCode}
          subject={item}
          testId={`${itemTestIdPrefix}-${item.subjectCode}-button`}
          isSelected={
            finalItems?.some((finalItem) => finalItem.id === item.id) || false
          }
        />
      ))}
    </GridContent>
  );
};
