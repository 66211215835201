import { useCallback, useState } from "react";
import { useOrganisation } from "contexts/OrganisationProvider";
import { useQuery } from "react-query";
import { logError } from "shared/services/ErrorReporting";
import { toast } from "utils/toast";
import { OrganisationOnixUploadFileUpload } from "./components/OrganisationOnixUploadFileUpload";
import { OrganisationOnixUploadFile } from "./components/OrganisationOnixUploadFile";
import { OrganisationOnixUploadFileReportDialog } from "./components/OrganisationOnixUploadFileReportDialog";

export const OrganisationOnixUploadPage = () => {
  const [reportId, setReportId] = useState<string | null>(null);

  const { addFile, getFiles, organisation } = useOrganisation();

  const { data, refetch } = useQuery(["getFiles"], () => getFiles(), {
    enabled: !!organisation?.id,
    onError: (error?: Error) => {
      if (error) {
        logError(error.message || error);
      }
      if (error?.message) {
        toast.error(error?.message);
      }
    },
  });

  const handleFileUpload = useCallback(
    ([file]: File[]) => {
      addFile(file)
        .catch((error) => {
          logError(error.message);
          toast.error(error.message);
        })
        .then(() => refetch());
    },
    [addFile, refetch],
  );

  return (
    <div>
      <OrganisationOnixUploadFileUpload onFileUpload={handleFileUpload} />
      <ul className="mt-8 flex flex-col gap-2">
        {data?.items?.map((file) => (
          <OrganisationOnixUploadFile
            key={file.name}
            fileName={file.name}
            fileType="Onix"
            fileSizeMB={(file.size || 0) / 1024 / 1024}
            uploadDate="09/09/2020"
            state="success"
            onActionClick={() => setReportId("1234")}
          />
        ))}
      </ul>
      <OrganisationOnixUploadFileReportDialog
        open={!!reportId}
        onClose={() => setReportId(null)}
        onExportClick={() => {}}
        succeeded={100}
        failed={100}
        total={200}
      />
    </div>
  );
};
