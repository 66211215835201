import { SearchQueryRequest, TypesenseProduct } from "__generated__/api";
import { IFilter } from "pages/Books/components/filter/keywordFilterTypes";
import { IProduct } from "integrations/firebase/collections";
import { BacklistInputType } from "pages/Books/table/types";
import { PaginationState } from "@tanstack/react-table";
import { logError } from "shared/services/ErrorReporting";
import { timestampFromUnix } from "../../integrations/firebase/utils";

export const mapFilter = (filter: IFilter): string => {
  let operator = "";
  if (filter.comparisonOperator === "EQUALS") {
    operator = "=";
  }
  if (filter.comparisonOperator === "GREATER") {
    operator = ">";
  }
  if (filter.comparisonOperator === "LESS") {
    operator = "<";
  }

  return `${filter.variable}:${operator}${filter.value}`;
};

const getQuickSearchQuery = (
  query: string,
  filter: IFilter | undefined,
  page: number,
  perPage: number,
) => {
  const filterBy = filter ? mapFilter(filter) : "";
  const quickSearchFields = ["title", "author", "identifier"];

  const searchParameters: Partial<SearchQueryRequest> = {
    q: query,
    query_by: quickSearchFields.join(","),
    sort_by: "publishedAt:desc",
    filter_by: filterBy,
    page,
    per_page: perPage,
    prefix: "false",
  };

  return searchParameters;
};

const getISBNSearchQuery = (isbns: string[]) => {
  const isbnString = isbns?.filter((s) => s).join(", ");
  const filterBy = isbnString ? `identifier: [${isbnString}]` : "";

  const searchParameters: Partial<SearchQueryRequest> = {
    filter_by: filterBy,
  };

  return searchParameters;
};

const getSemanticSearchQuery = (query: string) => {
  if (!query) {
    return getQuickSearchQuery(query, undefined, 1, 10);
  }
  const searchParameters: Partial<SearchQueryRequest> = {
    q: query,
    query_by: "summaryEmbedding",
    prefix: "false",
  };

  return searchParameters;
};

export const getQuery = (
  dropdownType: BacklistInputType,
  s: string | string[],
  filter: IFilter | undefined,
  pagination?: PaginationState,
) => {
  switch (dropdownType) {
    case BacklistInputType.QUICK: {
      if (typeof s !== "string") {
        logError(
          `Received array as searchTerm for quick search: ${JSON.stringify(s)}`,
        );
      }
      const query = typeof s === "string" ? s : s.join(" ");
      return getQuickSearchQuery(
        query,
        filter,
        pagination ? pagination.pageIndex + 1 : 1,
        pagination ? pagination.pageSize : 10,
      );
    }
    case BacklistInputType.ISBN: {
      if (typeof s === "string") {
        logError(`Received string as searchTerm for isbn search: ${s}`);
      }
      const query =
        typeof s === "string" ? s.split(",").map((v) => v.trim()) : s;
      return getISBNSearchQuery(query);
    }
    case BacklistInputType.SEMANTIC: {
      if (typeof s !== "string") {
        logError(
          `Received array as searchTerm for semantic search: ${JSON.stringify(
            s,
          )}`,
        );
      }

      const query = typeof s === "string" ? s : s.join(" ");
      return getSemanticSearchQuery(query);
    }
    default: {
      logError("No search parameters for dropdownType", dropdownType);
      return undefined;
    }
  }
};

export const mapTypesenseToProduct = (
  typesenseProduct: TypesenseProduct,
): IProduct => {
  const { createdAt, updatedAt, publishedAt } = typesenseProduct;

  return {
    ...typesenseProduct,
    id: typesenseProduct.identifier,
    isbn: typesenseProduct.identifier,
    publishedAt: publishedAt ? timestampFromUnix(publishedAt) : null,
    createdAt: timestampFromUnix(createdAt),
    updatedAt: timestampFromUnix(updatedAt),
  };
};
