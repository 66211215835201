import { Table } from "@tanstack/react-table";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import entries from "lodash/entries";
import { BacklistInput } from "../components/input/BacklistInput";
import { SaveAsProjectButton } from "./SaveAsProjectButton";

export const PreHeader = ({
  table,
  isSelectedAll,
  allBooksCount,
}: {
  table: Table<IProduct>;
  isSelectedAll?: boolean;
  allBooksCount: number;
}) => {
  const { t } = useTranslation(["books"]);

  const selection = table.getState().rowSelection;

  const selectedIsbns = useMemo(() => {
    const selected = entries(selection).filter(([, checked]) => checked);
    return selected.map(([id]) => id);
  }, [selection]);

  const selectedBooksCount = isSelectedAll
    ? allBooksCount
    : selectedIsbns.length;

  const clearSelection = useCallback(() => table.setRowSelection({}), [table]);

  return (
    <>
      <div className="flex flex-row gap-4 w-3/4 items-center">
        <BacklistInput onSearch={clearSelection} />
      </div>
      <div className="flex flex-row items-center gap-4">
        {selectedBooksCount > 0 ? (
          <p className="text-secondary-500 ml-4 text-nowrap">
            {isSelectedAll
              ? t("books:allSelected", { count: selectedBooksCount })
              : t("books:selected", { count: selectedBooksCount })}
          </p>
        ) : null}
        <SaveAsProjectButton
          isSelectedAll={isSelectedAll}
          selectedBooksCount={selectedBooksCount}
          selectedIsbns={selectedIsbns}
          allBooksCount={allBooksCount}
        />
      </div>
    </>
  );
};
