import orderBy from "lodash/orderBy";
import { flexRender } from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePreHeader,
  TableRow,
} from "components/ui/table";
import { useTable } from "hooks/useTable";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { PreHeader } from "./PreHeader";
import { columns } from "./columns";

interface DetailsTableProps {
  onRowClick: (product: IProduct) => void;
  isLoading?: boolean;
  products: IProduct[];
}

export const DetailsTable = ({
  onRowClick,
  isLoading,
  products,
}: DetailsTableProps) => {
  const { t } = useTranslation(["projectDetails"]);

  const { table } = useTable({
    enableRowSelection: true,
    columns: columns(t),
    data: orderBy(products ?? [], "isbn"),
    totalRecords: products?.length || 0,
  });

  return (
    <div className="space-y-4 text-left" data-testid="details-table">
      <Table>
        <TablePreHeader colSpan={table.getHeaderGroups()[0].headers.length}>
          <PreHeader table={table} />
        </TablePreHeader>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell>
                <div className="flex items-center justify-center w-full pt-10">
                  <LoadingSpinner size="100px" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {!isLoading && !table.getRowModel().rows?.length && (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t("projectDetails:table.noResults")}
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            table.getRowModel().rows &&
            table.getRowModel().rows.length > 0 &&
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className="cursor-pointer"
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    key={cell.id}
                    onClick={
                      index === 0
                        ? undefined
                        : () =>
                            !row.original.nonExistent &&
                            onRowClick(row.original)
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};
