import { Dialog, DialogContent } from "components/ui/dialog";
import { IconProps } from "components/icon/Icon";
import { ModalHeader } from "./ModalHeader";
import { ModalFooter } from "./ModalFooter";
import { ModalProps, ModalVariant } from "./Modal.types";

const variantDefaults: Record<ModalVariant, { iconName: IconProps["name"] }> = {
  primary: { iconName: "zap" },
  warning: { iconName: "triangle-alert" },
  danger: { iconName: "octagon-alert" },
};

export function Modal({
  id,
  open,
  loading = false,
  onOpenChange,
  onConfirm,
  title,
  description,
  confirmLabel,
  confirmIconName,
  cancelLabel,
  iconName,
  variant,
  children,
  confirmVariant,
  confirmClassName,
}: ModalProps) {
  const { iconName: defaultIconName } = variantDefaults[variant];

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="py-4 px-4 w-auto" data-testid={`${id}-modal`}>
        <ModalHeader
          title={title}
          description={description}
          iconName={iconName || defaultIconName}
          variant={variant}
        />
        {children}
        <ModalFooter
          id={id}
          confirmLabel={confirmLabel}
          confirmIconName={confirmIconName}
          confirmVariant={confirmVariant || variant}
          confirmClassName={confirmClassName}
          cancelLabel={cancelLabel}
          loading={loading}
          onConfirm={onConfirm}
        />
      </DialogContent>
    </Dialog>
  );
}
