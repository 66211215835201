import { useCallback } from "react";
import { useProduct } from "contexts/ProductContext";
import { FirestoreProductSubject } from "__generated__/models";
import { logError } from "shared/services/ErrorReporting";
import { BaseItem } from "pages/ProductDetails/components/SortableList";
import {
  filterSubjects,
  filterQualifiers,
} from "pages/ProductDetails/tabs/thema/utils";
import { useProductActions } from "./useProductActions";

export const useThema = () => {
  const { product } = useProduct();
  const { saveFinalSubjects } = useProductActions();

  const handleAddSubject = useCallback(
    async (subject: FirestoreProductSubject) => {
      if (!product) {
        logError("Product not found while adding subject");
        return;
      }
      if (subject) {
        const finalSubjects = product.subjects?.final || [];
        if (
          finalSubjects.findIndex(
            (sub) => sub.subjectCode === subject.subjectCode,
          ) === -1
        ) {
          await saveFinalSubjects(product.id, [...finalSubjects, subject]);
        } else {
          await saveFinalSubjects(
            product.id,
            finalSubjects.filter((k) => k.subjectCode !== subject.subjectCode),
          );
        }
      }
    },
    [product, saveFinalSubjects],
  );

  const handleChangeOrder = useCallback(
    async (
      items: BaseItem[],
      allFinalItems: FirestoreProductSubject[],
      productId: string,
      isSubjectList: boolean,
    ) => {
      const subjects = filterSubjects(allFinalItems);
      const qualifiers = filterQualifiers(allFinalItems);

      let newOrder: FirestoreProductSubject[];

      if (isSubjectList) {
        const newSubjects = items
          .map((item) => subjects.find((sub) => sub.subjectCode === item.value))
          .filter(
            (item): item is FirestoreProductSubject => item !== undefined,
          );

        newOrder = [...newSubjects, ...qualifiers];
      } else {
        const newQualifiers = items
          .map((item) =>
            qualifiers.find((qual) => qual.subjectCode === item.value),
          )
          .filter(
            (item): item is FirestoreProductSubject => item !== undefined,
          );

        newOrder = [...subjects, ...newQualifiers];
      }

      if (newOrder.length === 0) {
        return;
      }

      await saveFinalSubjects(productId, newOrder);
    },
    [saveFinalSubjects],
  );

  return { handleAddSubject, handleChangeOrder };
};
