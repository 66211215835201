import {
  addDoc,
  collection,
  FirestoreError,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { db } from "integrations/firebase/firestore";
import {
  FirestoreGeneratedStatus as GenerationStatus,
  FirestoreProductSubject,
} from "../../../__generated__/models";

export { GenerationStatus };

export interface Generation {
  id: string;
  mode: "THEMA_SUBJECT_CATEGORY";
  status: "REQUESTED";
  productId: string;
  userId: string;
}

export type IGeneratedSubject = FirestoreProductSubject["generated"];

const generationConverter = {
  toFirestore(generation: Generation) {
    return generation;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<Generation>): Generation {
    const data = snapshot.data();
    return {
      ...data,
    };
  },
};

export const getGeneratedCollection = () =>
  collection(db, "generationRequests").withConverter(generationConverter);

export const watchUserGenerations = (
  userId: string,
  onSuccess: (generations: Generation[]) => void,
  onError?: (error: FirestoreError) => void,
) => {
  const q = query(getGeneratedCollection(), where("userId", "==", userId));

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const generations =
        querySnapshot.docs?.map(
          (docum: QueryDocumentSnapshot) => docum.data() as Generation,
        ) || [];
      onSuccess(generations);
    },
    onError,
  );

  return unsubscribe;
};

export const createGeneratedSubject = async (
  productId: string,
  userId: string,
  organisation: { id: string; name: string },
) => {
  const objectToCreate = {
    mode: "THEMA_SUBJECT_CATEGORY",
    status: GenerationStatus.REQUESTED,
    productId,
    userId,
    organisation,
  };
  const generatedCollection = collection(db, "generationRequests");
  const docRef = await addDoc(generatedCollection, objectToCreate);
  return docRef.id;
};
