import { useCallback, useMemo, useState } from "react";
import { useAnalytics } from "contexts/AnalyticsContext";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { copyToClipboard } from "utils/helpers";
import { FinalHeader } from "components/layout/finalHeader";
import { toast } from "utils/toast";
import { useProduct } from "contexts/ProductContext";
import { logError } from "shared/services/ErrorReporting";
import {
  BaseItem,
  SortableList,
} from "pages/ProductDetails/components/SortableList";
import { FinalKeywordsEmpty } from "./FinalKeywordsEmpty";
import InputMode from "./InputMode";
import { CircleColorWarningCounterKeywordsCharactersCount } from "./CircleColorWarningCounterKeywordsCharactersCount";
import { googleAnalyticsKeywordSelectionReport } from "../../../../../../shared/services/KeywordService";

export const FinalKeywords = () => {
  const { t } = useTranslation(["productDetails"]);

  const [inputMode, setInputMode] = useState(false);

  const { gaEvent } = useAnalytics();
  const { product, onUpdateKeywords } = useProduct();

  const finalKeywords = useMemo(() => product?.finalKeywords ?? [], [product]);

  const listItems = useMemo(
    () =>
      finalKeywords.map((keyword, index) => ({
        id: index + 1,
        value: keyword,
      })),
    [finalKeywords],
  );

  const onSaveKeywords = useCallback(
    async (keywords: string[]) => {
      try {
        await onUpdateKeywords(keywords);
      } catch (e) {
        logError(e);
        toast.error(t("productDetails:errorToast.errorUpdate"));
      }
    },
    [onUpdateKeywords, t],
  );

  const onChangeKeywords = useCallback(
    async (items: BaseItem[]) => {
      await onSaveKeywords(items.map((item) => item.value));
    },
    [onSaveKeywords],
  );

  const handleRemoveFinalKeywordsListItem = useCallback(
    async (item: BaseItem | undefined) => {
      if (!item || !product) {
        return;
      }

      gaEvent({
        type: "remove_keyword_from_final_selection",
        payload: {
          keyword: item.value,
          book: product.isbn,
        },
      });
    },
    [gaEvent, product],
  );

  const hasKeywords = finalKeywords.length > 0;
  const finalKeywordsCharacters = finalKeywords.join("").trim().length;

  const onCopy = useCallback(async () => {
    gaEvent({
      type: "copy_keywords_from_final_selection",
      payload: googleAnalyticsKeywordSelectionReport(product),
    });

    await copyToClipboard({ arrayToCopy: finalKeywords });

    toast.success(t("finalKeywords.toasts.copy.header"));
  }, [finalKeywords, t, gaEvent, product]);

  return (
    <div className="rounded-xl border border-secondary-200 overflow-hidden min-w-80 min-h-10 ">
      <FinalHeader
        showAddButton
        inputMode={inputMode}
        setInputMode={setInputMode}
        label={t("finalKeywords.header")}
      />
      {!inputMode && !hasKeywords && <FinalKeywordsEmpty />}

      <div className="overflow-y-scroll">
        <SortableList
          items={listItems}
          onChange={onChangeKeywords}
          onRemove={handleRemoveFinalKeywordsListItem}
        />

        {inputMode && (
          <InputMode
            product={product}
            saveFinalKeywords={(keywords) => onSaveKeywords(keywords)}
            setInputMode={setInputMode}
          />
        )}
      </div>

      <div className="flex justify-between border-t border-secondary-200 p-4">
        <Button
          size="small"
          variant="tertiary"
          icon="copy"
          label={t("finalKeywords.actions.copy")}
          disabled={!hasKeywords}
          onClick={onCopy}
        />

        <CircleColorWarningCounterKeywordsCharactersCount
          count={finalKeywordsCharacters || 0}
        />
      </div>
    </div>
  );
};
