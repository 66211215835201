import { ColumnDef } from "@tanstack/react-table";

import { TFunction } from "i18next";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { FirestoreOrganisation } from "__generated__/models";
import { Button } from "../../../../components/button";
import { OrganisationName } from "./OrganisationName";

export const columns: (t: TFunction) => ColumnDef<FirestoreOrganisation>[] = (
  t: TFunction,
) => [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("settings:organisations.overview.table.columns.name")}
      />
    ),
    cell: ({ row }) => (
      <div className="w-[40%]">
        <OrganisationName
          id={row.id}
          isEditing={row.getIsEditing()}
          name={row.getValue("name")}
          onChange={() => row.toggleEditing(false)}
        />
      </div>
    ),
  },
  {
    accessorKey: "menu",
    header: ({ column }) => <DataTableColumnHeader column={column} title="" />,
    cell: ({ row }) => (
      <div className="justify-end flex">
        <Button
          label=""
          variant="ghost"
          icon="square-pen"
          aria-label={`Edit ${row.original.name} organisation name`}
          disabled
          onClick={() => row.toggleEditing(true)}
        />
      </div>
    ),
  },
];
