import { BacklistContextProvider } from "contexts/BacklistContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { BooksPageInternal } from "./BooksPageInternal";

export const BooksPage = () => {
  const queryClient = new QueryClient();

  return (
    <BacklistContextProvider>
      <QueryClientProvider client={queryClient}>
        <BooksPageInternal />
      </QueryClientProvider>
    </BacklistContextProvider>
  );
};
