import { Error } from "components/icon/Error";
import { useTranslation } from "react-i18next";

export function ContactUsError() {
  const { t } = useTranslation(["contactUs"]);
  return (
    <div className="w-full min-h-[50vh] mt-24 flex justify-center items-center">
      <div className="pb-4 flex flex-col items-center text-center gap-6 max-w-lg">
        <div className="mb-4">
          <Error />
        </div>

        <p className="pb-1 text-2xl font-semibold">{t("error.title")}</p>
        <p className="text-m text-secondary-400 font-light">
          {t("error.description")}
        </p>
      </div>
    </div>
  );
}
