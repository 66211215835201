import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { Input } from "components/ui/input";

import { Label } from "components/ui/label";
import { SignUpSchemaType } from "../utils";
import { CategorySelect } from "./CategorySelect";
import { ISBNField } from "./ISBNField";
import { MessageField } from "./MessageField";

interface FormFieldsProps {
  formMethods: UseFormReturn<SignUpSchemaType>;
}

export const FormFields = ({ formMethods }: FormFieldsProps) => {
  const { t } = useTranslation(["contactUs"]);
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = formMethods;

  return (
    <>
      <div className="grid grid-cols-2 gap-8">
        <div>
          <Label className="text-s">{t("contactUs:form.name.label")}</Label>
          <Input
            placeholder={t("contactUs:form.name.placeholder")}
            {...register("name")}
            disabled
          />
        </div>
        <div>
          <Label className="text-s">{t("contactUs:form.email.label")}</Label>
          <Input
            placeholder={t("contactUs:form.email.placeholder")}
            {...register("email")}
            disabled
          />
        </div>
        <CategorySelect control={control} />
        <ISBNField register={register} errors={errors} />
      </div>
      <MessageField register={register} watch={watch} />
    </>
  );
};
