import { useProduct } from "contexts/ProductContext";
import { FinalThemaList } from "./finalList/finalThemaList";
import { ThemaTabs } from "./ThemaTabs";

export const ThemaTab = () => {
  const { product } = useProduct();
  if (!product) {
    return;
  }

  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col flex-1">
        <div className="min-h-40 h-full">
          <ThemaTabs />
        </div>
      </div>
      <div className="max-w-80 min-h-36">
        <FinalThemaList />
      </div>
    </div>
  );
};
