import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "contexts/AuthContext";
import { GlobalLoading } from "components/loading";
import "shared/services/ErrorReporting";
import { ErrorBoundary } from "@sentry/react";
import { ErrorFallback } from "components/errors/ErrorFallbackScreen";
import { AnalyticsContextProvider } from "contexts/AnalyticsContext";
import { Toaster } from "components/ui/sonner";
import { FeatureFlagProvider } from "contexts/FeatureFlagContext";
import AppRoutes from "./AppRoutes";

const App: React.FC = () => (
  <Suspense fallback={<GlobalLoading />}>
    <ErrorBoundary fallback={ErrorFallback}>
      <AuthContextProvider>
        <FeatureFlagProvider>
          <BrowserRouter>
            <AnalyticsContextProvider>
              <AppRoutes />
            </AnalyticsContextProvider>
          </BrowserRouter>
        </FeatureFlagProvider>
      </AuthContextProvider>
      <Toaster />
    </ErrorBoundary>
  </Suspense>
);

export default App;
