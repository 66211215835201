import { useCallback } from "react";
import { arrayRemove, arrayUnion } from "firebase/firestore";
import { useAnalytics } from "contexts/AnalyticsContext";
import { logError } from "shared/services/ErrorReporting";
import { useProductActions } from "./useProductActions";
import { useProduct } from "../contexts/ProductContext";

export const useKeywords = () => {
  const { gaEvent } = useAnalytics();
  const { product, onUpdateKeywords } = useProduct();
  const { errorUpdate, isLoadingUpdate } = useProductActions();

  const handleAddKeyword = useCallback(
    async (keyword: string, { type }: { type: "database" | "generated" }) => {
      if (!product) {
        logError("Product not found while adding keyword");
        return;
      }

      if (!keyword) {
        return;
      }

      const keywords = product.finalKeywords ?? [];

      const update = keywords.includes(keyword)
        ? arrayRemove(keyword)
        : arrayUnion(keyword);

      await onUpdateKeywords(update);

      if (keywords.includes(keyword)) {
        gaEvent({
          type: "remove_keyword_from_final_selection",
          payload: {
            keyword,
            book: product.id ?? "",
          },
        });
      } else {
        gaEvent({
          type:
            type === "generated"
              ? "add_generated_keyword_to_final_selection"
              : "add_database_keyword_to_final_selection",
          payload: {
            keyword,
            book: product.id ?? "",
          },
        });
      }
    },
    [gaEvent, product, onUpdateKeywords],
  );

  return { handleAddKeyword, errorUpdate, isLoadingUpdate };
};
