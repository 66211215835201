import { Client, createClient } from "@hey-api/client-fetch";
import { search, SearchQueryRequest } from "__generated__/api";
import { searchResponseSchema } from "__generated__/api/validation.gen";
import { parseResult } from "../parseHelper";
import { AccountApi } from "./account";

export const baseUrl = import.meta.env.VITE_API_URL;

export class Api {
  client: Client;

  account: AccountApi;

  constructor(authToken?: string) {
    this.client = createClient({ baseUrl });

    this.setAuthToken(authToken);

    this.account = new AccountApi(this.client);
  }

  async search(query: SearchQueryRequest) {
    const response = await search({ body: query, client: this.client });

    const result = parseResult(searchResponseSchema, response, query);

    return result?.data;
  }

  setAuthToken(authToken?: string) {
    this.client.setConfig({
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
      },
    });
  }
}

export const api = new Api();
