import { useTranslation } from "react-i18next";
import { Grid, GridHeader, GridTitle, GridBody } from "components/ui/grid";
import { BaseListContent } from "components/thema/BaseListContent";
import { useQualifiersData } from "./useQualifiersData";
import { QualifiersListProps } from "./types";

export const QualifiersList = ({
  variant = "generated",
}: QualifiersListProps) => {
  const { t } = useTranslation(["productDetails"]);
  const { qualifiers, finalQualifiers, isEmptyList } =
    useQualifiersData(variant);

  return (
    <Grid className="h-full">
      <GridHeader>
        <GridTitle>{t("thema.headers.qualifiers")}</GridTitle>
      </GridHeader>
      <GridBody className="h-full">
        <BaseListContent
          isEmptyList={isEmptyList}
          items={qualifiers}
          finalItems={finalQualifiers}
          variant="vertical"
          emptyListProps={{
            title:
              variant === "generated"
                ? t("thema.sections.noQualifiers.generated.title")
                : t("thema.sections.noQualifiers.database.title"),
            description:
              variant === "generated"
                ? t("thema.sections.noQualifiers.generated.description")
                : t("thema.sections.noQualifiers.database.description"),
          }}
          itemTestIdPrefix="add-qualifier"
        />
      </GridBody>
    </Grid>
  );
};
