import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { Trans, useTranslation } from "react-i18next";
import { logError } from "shared/services/ErrorReporting";
import { useAnalytics } from "contexts/AnalyticsContext";
import { FirebaseError } from "firebase/app";
import { Button } from "components/button";
import ReedyLogo from "assets/svg/reedy-logo.svg?react";
import GoogleLogo from "assets/svg/google-logo.svg?react";
import { toast } from "utils/toast";
import { AuthErrorCodes } from "firebase/auth";
import { CircleHelp } from "lucide-react";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import useAuthRedirect from "hooks/auth/useAuthRedirect";
import { LoginForm } from "./components/LoginForm";

export type ErrorCodes = (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];

export const Login: React.FC = () => {
  useAuthRedirect();

  const { t } = useTranslation(["general", "login"]);
  const { features } = useFeatureFlags();

  const { signInWithOauth } = useAuth();

  const navigate = useNavigate();

  const [errorLogin, setErrorLogin] = useState<Error>();
  const { gaEvent } = useAnalytics();

  const resetErrorLogin = () => setErrorLogin(undefined);

  useEffect(() => {
    if (errorLogin) {
      toast.error(`${t("login:error.loginFailed")}: ${errorLogin?.message}`, {
        onAction: resetErrorLogin,
      });
    }
  }, [errorLogin, t]);

  const loginWithGoogle = useCallback(async () => {
    try {
      await signInWithOauth("google");

      gaEvent({ type: "login", payload: { method: "google" } });

      navigate(RoutePath.Home);

      toast.success(t("login:success.toast.title"));
    } catch (error) {
      const code = (error as FirebaseError)?.code as ErrorCodes;

      if (
        code === AuthErrorCodes.POPUP_CLOSED_BY_USER ||
        code === AuthErrorCodes.EXPIRED_POPUP_REQUEST
      ) {
        return;
      }

      logError(error);
      setErrorLogin(error as Error);
    }
  }, [signInWithOauth, gaEvent, t, navigate]);

  return (
    <div className="container mx-auto grid lg:grid-cols-[300px,1fr] gap-2 px-10 md:px-36 min-h-full">
      <div className="flex flex-col justify-center max-w-xl pt-16 mx-auto">
        <ReedyLogo className="-mx-4 mb-24" width={185} height={96} />

        <div className="space-y-10">
          <div className="space-y-4">
            <div className="text-5xl font-semibold">{t("login:title")}</div>
            <div className="font-medium">{t("login:subtitle")}</div>
          </div>

          <div className="grid gap-3">
            <Button
              label=""
              onClick={loginWithGoogle}
              variant="tertiary"
              size="small"
              className="!text-s font-normal flex items-center justify-center gap-2"
              analyticsId="google-login-button"
            >
              <GoogleLogo className="h-4 w-4" />
              {t("login:buttons.google")}
            </Button>

            {features.login.link && (
              <>
                <div className="relative flex items-center justify-center">
                  <hr className="absolute left-0 right-0 top-1/2 border-secondary-200" />
                  <div className="bg-white text-s text-secondary-600 font-light relative px-2">
                    {t("login:or")}
                  </div>
                </div>

                <LoginForm />
              </>
            )}

            {features.login.agreement && (
              <div className="text-s text-secondary-400 font-light px-1">
                <Trans
                  i18nKey="login:terms-agreement"
                  components={{
                    u: <span className="underline underline-offset-2" />,
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex-1" />

        <Link
          to={`mailto:${t("general:chaptrEmail")}`}
          className="text-s font-normal text-secondary-400 flex items-center py-10"
        >
          <CircleHelp className="inline-block mr-2 h-5 w-5" />
          {t("login:support")}
        </Link>
      </div>

      <div>
        <img
          alt="login illustration"
          className="object-cover overflow-visible object-left h-full"
          src="/images/login-image.png"
        />
      </div>
    </div>
  );
};
