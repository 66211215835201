import { Table } from "@tanstack/react-table";
import { Icon } from "components/icon/Icon";
import { IProduct } from "integrations/firebase/collections";

import { formatFirebaseTimestampDayTime } from "shared/utils";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useErrorToast } from "hooks/useErrorToast";
import { toast } from "utils/toast";
import { LoaderIcon } from "lucide-react";
import { useProjectDetails } from "../contexts/ProjectDetailsContext";
import { BulkGenerateButton } from "./BulkGenerateButton";
import { ExtractButton } from "./ExtractButton";
import { saveProductsAsExcel } from "../../../shared/services/ExportProductsService";
import { useAnalytics } from "../../../contexts/AnalyticsContext";

interface PreHeaderProps {
  table: Table<IProduct>;
}

export const PreHeader = ({ table }: PreHeaderProps) => {
  const {
    project,
    generateKeywords,
    generateDescriptions,
    isLoadingGenerate,
    errorGenerate,
  } = useProjectDetails();
  const created = formatFirebaseTimestampDayTime(project?.createdAt!);
  const { t } = useTranslation(["projectDetails"]);
  const selectedProducts = table
    .getSelectedRowModel()
    .rows.map((row) => row.original);
  const { gaEvent } = useAnalytics();

  useErrorToast(
    errorGenerate ? t("projectDetails:toast.errorGenerate") : undefined,
  );

  useEffect(() => {
    if (isLoadingGenerate) {
      toast.success(t("projectDetails:toast.preGeneratingMetadata"), {
        icon: <LoaderIcon className="animate-spin duration-2000 w-5 h-5" />,
        id: "pre-generating-metadata-toast",
      });
    } else if (errorGenerate) {
      toast.dismiss("pre-generating-metadata-toast");
    }
  }, [isLoadingGenerate, errorGenerate, t]);

  const handleExtractButtonClick = useCallback(() => {
    gaEvent({
      type: "export_books",
      payload: { books_count: selectedProducts.length },
    });
    saveProductsAsExcel(selectedProducts, project?.name || "project");
  }, [gaEvent, selectedProducts, project]);

  return (
    <div className="flex flex-row w-full justify-between items-center">
      <div className="flex flex-row gap-2 items-center">
        <Icon name="calendar" size="medium" />
        <p className="text-secondary-500">{created.date}</p>
        <p className="text-secondary-500 font-normal">{created.time}</p>
      </div>
      <div className="flex gap-2 items-center">
        {selectedProducts.length > 0 ? (
          <p className="text-secondary-500 ml-4 text-nowrap">
            {t("projectDetails:selected", { count: selectedProducts.length })}
          </p>
        ) : null}

        <BulkGenerateButton
          products={selectedProducts}
          generateKeywords={generateKeywords}
          generateDescriptions={generateDescriptions}
        />

        <ExtractButton
          onClick={handleExtractButtonClick}
          disabled={selectedProducts.length === 0}
        />
      </div>
    </div>
  );
};
