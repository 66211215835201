import { FC, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { IProduct, IProject } from "integrations/firebase/collections";
import { Button } from "components/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { useDialog } from "hooks/useDialog";
import { saveProductsAsExcel } from "shared/services/ExportProductsService";
import { useTable } from "hooks/useTable";
import { ExportTable } from "./ExportTable";
import { columns } from "./columns";
import { useAnalytics } from "../../contexts/AnalyticsContext";

type ExportModalProps = {
  products?: IProduct[];
  project?: IProject;
  buttonVariant?: "primary" | "tertiary";
};

const emptyArray: IProduct[] = [];

export const ExportModal: FC<ExportModalProps> = ({
  products,
  project,
  buttonVariant = "tertiary",
}) => {
  const { t } = useTranslation(["general", "components"]);

  const { onClose, isOpen, onOpen, onOpenChange } = useDialog();
  const { gaEvent } = useAnalytics();

  const { table } = useTable({
    columns,
    data: products || emptyArray,
    enableRowSelection: true,
    totalRecords: products?.length || 0,
  });

  const handleSave = useCallback(() => {
    const selectedProducts = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original);

    gaEvent({
      type: "export_books",
      payload: { books_count: selectedProducts.length },
    });

    saveProductsAsExcel(selectedProducts, project?.name ?? "project");

    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, table]);

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange} modal defaultOpen={false}>
      <DialogTrigger asChild>
        <Button
          onClick={onOpen}
          label={t("general:buttons.export")}
          icon="upload"
          variant={buttonVariant}
          analyticsId="button_export_products"
        />
      </DialogTrigger>
      <DialogContent className="max-w-[35rem]" data-testid="export-modal">
        <DialogHeader>
          <DialogTitle>{t("components:exportModal.title")}</DialogTitle>
          <DialogDescription>
            {t("components:exportModal.subtitle")}
          </DialogDescription>
        </DialogHeader>
        <ExportTable table={table} />
        <DialogFixedFooter>
          <DialogClose asChild>
            <Button
              variant="tertiary"
              label={t("components:exportModal.buttons.cancel")}
              size="small"
              analyticsId="button_cancel_export_products"
            />
          </DialogClose>

          <Button
            size="small"
            variant="primary"
            label={t("components:exportModal.buttons.export")}
            onClick={handleSave}
            testId="export-products-button"
            disabled={!table.getSelectedRowModel().flatRows.length}
            icon="upload"
            analyticsId="button_confirm_export_products"
          />
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
