import { CurrentProductContextProvider } from "contexts/CurrentProductContext";
import { TrendsContextProvider } from "contexts/TrendsContext";
import { ProjectsContextProvider } from "pages/Projects/contexts/ProjectsContext";
import { Layout } from "components/layout";
import { Navigate, Outlet } from "react-router-dom";
import { ProfileModal } from "components/modal/ProfileModal";
import { useAuth } from "contexts/AuthContext";

export function AuthLayout() {
  const { authUser } = useAuth();

  if (!authUser) {
    return <Navigate to="/login" />;
  }

  return (
    <CurrentProductContextProvider>
      <TrendsContextProvider>
        <ProjectsContextProvider>
          <Layout>
            <Outlet />
            <ProfileModal />
          </Layout>
        </ProjectsContextProvider>
      </TrendsContextProvider>
    </CurrentProductContextProvider>
  );
}
