import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "components/button";
import { Badge } from "components/ui/badge";

export const OrganisationOnixUploadFileReportDialog = ({
  open,
  onClose,
  onExportClick,
  total,
  succeeded,
  failed,
}: {
  open: boolean;
  onClose: () => void;
  onExportClick: () => void;
  total?: number;
  succeeded?: number;
  failed?: number;
}) => {
  const { t } = useTranslation(["settings"]);

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent className="overflow-visible min-w-[90%] min-h-[70%]">
        <DialogHeader className="items-start space-y-0 my-0 mb-8">
          <DialogTitle className="mb-3">
            {t("settings:organisation.onix.report.dialog.title")}
          </DialogTitle>
          <DialogDescription className="text-left">
            <span className="text-secondary-400 uppercase mr-2">
              {t("settings:organisation.onix.report.dialog.subtitle")}
            </span>
            <span className="text-secondary-500">
              {t("settings:organisation.onix.misc.books", { count: total })}
            </span>
            {failed ? (
              <>
                <span className="text-secondary-300 mx-2">|</span>
                <span className="text-secondary-500">
                  {t("settings:organisation.onix.misc.books", {
                    count: failed,
                  })}
                </span>
                <Badge variant="error" className="ml-2">
                  {t("settings:organisation.onix.misc.badge.failed")}
                </Badge>
              </>
            ) : null}
            {succeeded ? (
              <>
                <span className="text-secondary-300 mx-2">|</span>
                <span className="text-secondary-500">
                  {t("settings:organisation.onix.misc.books", {
                    count: succeeded,
                  })}
                </span>
                <Badge variant="success" className="ml-2">
                  {t("settings:organisation.onix.misc.badge.success")}
                </Badge>
              </>
            ) : null}
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-5 flex-grow" />

        <DialogFixedFooter className="flex justify-between gap-2 px-5 items-center">
          <div className="flex justify-end gap-2 ml-auto">
            <Button
              label={t("settings:organisation.onix.report.dialog.cancel")}
              onClick={onClose}
              variant="tertiary"
              size="small"
            />
            <Button
              label={t("settings:organisation.onix.report.dialog.export")}
              size="small"
              onClick={onExportClick}
            />
          </div>
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
