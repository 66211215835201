import { useTranslation } from "react-i18next";
import { TabHeader } from "components/TabHeader";
import { SearchTermsTable } from "./table/SearchTermsTable";

export const SearchTermsPage = () => {
  const { t } = useTranslation(["searchTerms"]);
  return (
    <div>
      <TabHeader
        title={t("searchTerms:header.title")}
        subtitle={t("searchTerms:header.subtitle")}
      />
      <SearchTermsTable />
    </div>
  );
};
