import { useGenerations } from "hooks/useGenerations";
import { useProduct } from "contexts/ProductContext";
import { GridLoading } from "components/ui/grid";
import { KeywordsLoading } from "components/skeletons/KeywordsLoading";
import { useTranslation } from "react-i18next";
import { useProductActions } from "hooks/useProductActions";
import { QualifiersList } from "./QualifiersList";
import { SubjectsList } from "./SubjectsList";
import { useSubjectsData } from "./SubjectsList/useSubjectsData";
import { GeneratedSubjectsEmpty } from "./GeneratedSubjectsEmpty";

export const GeneratedCodes = () => {
  const { product } = useProduct();
  const { t } = useTranslation(["productDetails"]);
  const { generateProductSubjects } = useProductActions();
  const { generations } = useGenerations();
  const { isEmptyList: isEmptySubjectsList, isGeneratingProduct } =
    useSubjectsData("generated", generations, product);
  const { isEmptyList: isEmptyQualifiersList } = useSubjectsData(
    "generated",
    [],
    product,
  );

  if (isGeneratingProduct) {
    return (
      <GridLoading className="w-full h-full" loading>
        <div className="relative w-80 ml-20 align-middle">
          <div className="absolute -left-64">
            <KeywordsLoading />
          </div>
          <p className="text-center text-sm font-medium">
            {t("thema.loading")}
          </p>
        </div>
      </GridLoading>
    );
  }

  if (isEmptySubjectsList && isEmptyQualifiersList) {
    return (
      <GeneratedSubjectsEmpty
        onClick={() => generateProductSubjects(product?.id || "")}
      />
    );
  }

  return (
    <div className="flex flex-row gap-4 h-full">
      <SubjectsList />
      <QualifiersList />
    </div>
  );
};
