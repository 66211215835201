import React from "react";
import { useTranslation } from "react-i18next";
import { DialogControl } from "hooks/useDialog";
import { Modal } from "components/modal/Modal";

interface OrganisationAdminRequiredModalProps {
  control: DialogControl;
}

export const OrganisationAdminRequiredModal = ({
  control,
}: OrganisationAdminRequiredModalProps) => {
  const { t } = useTranslation(["settings"]);

  return (
    <Modal
      id="organisation-admin-required"
      title={t("settings:organisation.members.role.admin-required.title")}
      description={t(
        "settings:organisation.members.role.admin-required.description",
      )}
      confirmLabel={t(
        "settings:organisation.members.role.admin-required.confirm",
      )}
      cancelLabel={t(
        "settings:organisation.members.role.admin-required.cancel",
      )}
      open={control.isOpen}
      onOpenChange={control.onOpenChange}
      variant="warning"
      iconName="octagon-alert"
      onConfirm={control.close}
    />
  );
};
