import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";

export const HomeHeader = () => {
  const { userData } = useAuth();
  const { t } = useTranslation(["home"]);

  const getTimeOfDay = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return t(`home:greeting.morning`, { name: userData?.givenName });
    }
    if (currentHour < 18) {
      return t(`home:greeting.afternoon`, { name: userData?.givenName });
    }
    return t(`home:greeting.evening`, { name: userData?.givenName });
  };

  const greeting = getTimeOfDay();

  return (
    <div className="flex flex-col gap-4">
      <p className="text-secondary-800 text-2xl font-semibold text-center">
        {greeting}
      </p>
      <p className="text-secondary-800 text-7xl leading-0 font-semibold text-center">
        {t("home:greeting.description")}
      </p>
    </div>
  );
};
