import { useTable } from "hooks/useTable";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { useTranslation } from "react-i18next";
import { flexRender } from "@tanstack/react-table";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { FirestoreOrganisation } from "__generated__/models";
import { columns } from "./organisationTableColumns";

interface OrganisationTableProps {
  organisations: FirestoreOrganisation[];
}

export const OrganisationsTable = ({
  organisations,
}: PropsWithChildren<OrganisationTableProps>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { table } = useTable({
    columns: columns(t),
    data: organisations,
    totalRecords: organisations.length,
    enableRowSelection: false,
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell, index, arr) => (
                <TableCell
                  key={cell.id}
                  onClick={
                    index === arr.length - 1 || row.getIsEditing()
                      ? undefined
                      : () => navigate(row.id)
                  }
                >
                  {flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    row,
                  })}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
