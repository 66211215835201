import { Route, Routes } from "react-router-dom";
import { ContactUs } from "pages/ContactUs";

import { HomePage } from "pages/Home/HomePage";
import { RoutePath } from "shared/constants";
import { SettingsRoute } from "pages/Settings/SettingsRoute";
import { BooksPage } from "./pages/Books/BooksPage";
import { ProjectsPage } from "./pages/Projects/ProjectsPage";
import { ProductDetails } from "./pages/ProductDetails/ProductDetails";
import { SearchTermsPage } from "./pages/SearchTerms/SearchTermsPage";
import { SelectedSearchTermsPage } from "./pages/SelectedSearchTerms/selectedPage";
import { NotFound } from "./pages/NotFound";
import { ProjectDetails } from "./pages/ProjectDetails";
import { ProductLayout } from "./pages/ProductDetails/ProductLayout";
import { ProjectLayout } from "./pages/Projects/ProjectLayout";
import { Profile } from "./pages/Profile";

export function AuthRoutes() {
  return (
    <Routes>
      <Route path={RoutePath.Profile} element={<Profile />} />
      <Route path="settings/*" element={<SettingsRoute />} />
      <Route path={RoutePath.Home} element={<HomePage />} />

      <Route path={RoutePath.Books}>
        <Route index element={<BooksPage />} />
        <Route path=":idProduct" element={<ProductLayout />}>
          <Route index element={<ProductDetails />} />
        </Route>
      </Route>

      <Route path={RoutePath.Projects}>
        <Route index element={<ProjectsPage />} />

        <Route path=":idProject" element={<ProjectLayout />}>
          <Route index element={<ProjectDetails />} />

          <Route path="product">
            <Route path=":idProduct" element={<ProductLayout />}>
              <Route index element={<ProductDetails />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path={RoutePath.SearchTerms}>
        <Route index element={<SearchTermsPage />} />

        <Route path="selected-books" element={<SelectedSearchTermsPage />} />
      </Route>
      <Route path={RoutePath.ContactUs} element={<ContactUs />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
