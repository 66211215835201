import { GenerationStatus } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import {
  Grid,
  GridBody,
  GridButtonContainer,
  GridContent,
  GridHeader,
  GridLoading,
  GridTitle,
} from "components/ui/grid";
import { KeywordsLoading } from "components/skeletons/KeywordsLoading";
import { useProductActions } from "hooks/useProductActions";
import { useProduct } from "contexts/ProductContext";
import { toast } from "utils/toast";
import { logError } from "shared/services/ErrorReporting";
import { KeywordItem } from "../KeywordItem";
import { RegenerateKeywordsDialogContent } from "./RegenerateKeywordsDialogContent";
import { GeneratedKeywordsEmpty } from "./GeneratedKeywordsEmpty";

export const GeneratedKeywords = () => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();

  const generatedKeywords = product?.generated?.keywords;
  const finalKeywords = useMemo(() => product?.finalKeywords ?? [], [product]);

  const { generateProductKeywords } = useProductActions();
  const removedDuplicateKeywords = generatedKeywords?.data?.filter(
    (keyword, index, self) => self.indexOf(keyword) === index,
  );

  const handleGenerate = async () => {
    if (!product) {
      logError("Product not found while generating keywords");
      toast.error(
        t("productDetails:generatedKeywords.errorToast.productNotFound"),
      );

      return;
    }

    try {
      await generateProductKeywords(product);
    } catch (e) {
      logError(e);
      toast.error(
        t("productDetails:generatedKeywords.errorToast.errorGenerate"),
      );
    }
  };

  return (
    <Grid>
      <GridHeader>
        <GridTitle>{t("productDetails:generatedKeywords.header")}</GridTitle>
        <GridButtonContainer>
          {generatedKeywords?.status !== GenerationStatus.REQUESTED &&
          generatedKeywords?.data?.length ? (
            <RegenerateKeywordsDialogContent />
          ) : null}
        </GridButtonContainer>
      </GridHeader>
      <GridBody className="h-full min-h-[20.25rem]">
        <GridLoading
          className=" w-full h-full"
          loading={generatedKeywords?.status === GenerationStatus.REQUESTED}
        >
          <div className="relative w-80 ml-20 align-middle">
            <div className="absolute -left-64">
              <KeywordsLoading />
            </div>
            <p className="text-center text-sm font-medium">
              {t("productDetails:generatedKeywords.loading.one")}
              <br />
              {t("productDetails:generatedKeywords.loading.two")}
            </p>
          </div>
        </GridLoading>
        {generatedKeywords?.status !== GenerationStatus.REQUESTED &&
        generatedKeywords?.data?.length ? (
          <GridContent>
            {removedDuplicateKeywords?.map((keyword) => (
              <KeywordItem
                type="generated"
                key={keyword}
                keyword={keyword}
                testId={`add-${keyword}-button`}
                isSelected={finalKeywords.includes(keyword)}
              />
            ))}
          </GridContent>
        ) : null}
        {generatedKeywords?.status !== GenerationStatus.REQUESTED &&
          !generatedKeywords?.data?.length && (
            <GeneratedKeywordsEmpty onClick={handleGenerate} />
          )}
      </GridBody>
    </Grid>
  );
};
