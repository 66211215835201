import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { createTicket, TicketCategory } from "integrations/zendesk";
import { useSearchParams } from "react-router-dom";
import { signUpSchema, SignUpSchemaType } from "./utils";
import { ZendeskStatusCodes } from "./constants";
import { FormFields } from "./FormFields";
import { SubmitButton } from "./SubmitButton";

interface ContactUsFormProps {
  setFormStatus: (status: "success" | "error") => void;
}

export const ContactUsForm = ({ setFormStatus }: ContactUsFormProps) => {
  const { authUser, userData } = useAuth();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation(["contactUs"]);
  const { displayName, email } = authUser || {};
  const [searchParams] = useSearchParams();
  const isbn = searchParams.get("isbn");
  const ticketCategory = searchParams.get("ticketCategory");
  const formMethods = useForm<SignUpSchemaType>({
    resolver: zodResolver(signUpSchema(t)),
    defaultValues: {
      email: email || "",
      name: displayName || "",
      category: (ticketCategory as TicketCategory) || undefined,
      isbn: isbn || "",
      message: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSave = async (values: SignUpSchemaType) => {
    setSubmitLoading(true);
    const result = await createTicket({
      subject:
        values.message.length > 100
          ? `${values.message.slice(0, 97)}...`
          : values.message,
      name: values.name,
      email: values.email!,
      message: `A user provided feedback to a message:\n\n${values.message}`,
      category: values.category as TicketCategory,
      metadata: {
        isbn: values.isbn,
        organisation: userData?.organisation.name,
      },
    });

    setFormStatus(
      result.status === ZendeskStatusCodes.CREATED ? "success" : "error",
    );
    setSubmitLoading(false);
  };

  return (
    <div className="p-9 bg-secondary-25 rounded-lg mt-3">
      <form className="grid gap-y-6 gap-x-8" onSubmit={handleSubmit(onSave)}>
        <FormFields formMethods={formMethods} />
        <SubmitButton isValid={isValid} submitLoading={submitLoading} />
      </form>
    </div>
  );
};
