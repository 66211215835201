import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { IProject } from "integrations/firebase/collections";
import { useAnalytics } from "contexts/AnalyticsContext";
import { ProjectsTable } from "components/projectsTable/ProjectsTable";
import { HomeHeader } from "./components/HomeHeader";
import { HomeSearch } from "./components/HomeSearch";

export const HomePage = () => {
  const { t } = useTranslation(["home"]);
  const { gaEvent } = useAnalytics();
  const navigate = useNavigate();

  const handleRowClicked = (row: IProject) => {
    navigate(RoutePath.ProjectDetails.replace(":idProject", row.id!));
    gaEvent({
      type: "view_item_list",
      payload: {
        item_list_name: row.name,
        item_list_id: row.id,
        item_category: "project",
      },
    });
  };

  return (
    <div className="h-screen">
      <div className="h-1/2 bg-secondary-50 -mx-16 -mt-8 mb-8 flex flex-col justify-center items-center p-16 gap-8">
        <HomeHeader />
        <HomeSearch />
        <p className="text-secondary-400 max-w-xl text-center">
          {t("home:search.description")}
        </p>
      </div>
      <div className="w-full bg-white">
        <ProjectsTable
          onRowClicked={handleRowClicked}
          variant="compact"
          myProjectsOnly
        />
      </div>
    </div>
  );
};
