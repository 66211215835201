import { useState } from "react";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { useDialog } from "hooks/useDialog";
import { useOrganisation } from "contexts/OrganisationProvider";
import { useAuth } from "contexts/AuthContext";
import { toast } from "utils/toast";
import { Modal } from "components/modal/Modal";
import { UserRole } from "__generated__/api";

interface DeleteOrganisationMemberModalProps {
  userId: string;
  email: string;

  showAdminRequiredModal(): void;
}

export const DeleteOrganisationMemberModal = ({
  userId,
  email,
  showAdminRequiredModal,
}: DeleteOrganisationMemberModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation(["settings"]);
  const dialog = useDialog("delete-organisation-member");

  const { authUser } = useAuth();
  const { removeUser, members } = useOrganisation();

  async function onConfirm() {
    if (isDeleting) {
      return;
    }

    setIsDeleting(true);

    try {
      await removeUser(userId);

      toast.success(
        t("settings:organisation.members.delete.success", {
          email,
        }),
      );

      dialog.close();
    } catch (e) {
      toast.error(t("settings:organisation.members.delete.error"));
    }

    setIsDeleting(false);
  }

  if (authUser?.uid === userId) {
    return null;
  }

  const onDelete = () => {
    const admins = members.filter((m) => m.roles?.includes(UserRole.ADMIN));

    // If there's only 1 admin, and they're being changed to another role, display a warning
    if (admins.length === 1 && admins[0].id === userId) {
      showAdminRequiredModal();
      return;
    }

    dialog.open();
  };

  return (
    <>
      <Button
        icon="trash2"
        variant="ghost"
        className="text-red-400 hover:text-red-600"
        onClick={onDelete}
        testId="delete-organisation-member-button"
      />

      <Modal
        id={dialog.id!}
        open={dialog.isOpen}
        iconName="octagon-alert"
        variant="danger"
        title={t("settings:organisation.members.delete.title")}
        description={t("settings:organisation.members.delete.description", {
          email,
        })}
        confirmLabel={t("settings:organisation.members.delete.actions.confirm")}
        confirmIconName="trash2"
        confirmVariant="danger"
        cancelLabel={t("settings:organisation.members.delete.actions.cancel")}
        onOpenChange={dialog.onOpenChange}
        loading={isDeleting}
        onConfirm={() => onConfirm()}
      />
    </>
  );
};
