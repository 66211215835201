import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "components/ui/select";
import { UserRole } from "__generated__/api";
import { toast } from "utils/toast";
import { useOrganisation } from "contexts/OrganisationProvider";
import { getUserRoleLabel, UserRoleLabel } from "./UserRoleLabel";

interface UpdateOrganisationMemberUserRoleProps {
  userId: string;
  role: UserRole;
  email: string;

  showAdminRequiredModal(): void;
}

export const UpdateOrganisationMemberUserRole = ({
  userId,
  role,
  email,
  showAdminRequiredModal,
}: UpdateOrganisationMemberUserRoleProps) => {
  const { t } = useTranslation(["settings"]);

  const { userData } = useAuth();
  const { updateUserRole, members } = useOrganisation();

  const options = useMemo(() => {
    const roles = [UserRole.MEMBER, UserRole.ADMIN];

    if (userData?.roles?.includes(UserRole.SUPER_ADMIN)) {
      roles.push(UserRole.SUPER_ADMIN);
    }

    return roles;
  }, [userData]);

  const onChange = async (value: UserRole) => {
    const admins = members.filter((m) => m.roles?.includes(UserRole.ADMIN));

    // If there's only 1 admin, and they're being changed to another role, display a warning
    if (
      admins.length === 1 &&
      admins[0].id === userId &&
      value !== UserRole.ADMIN
    ) {
      showAdminRequiredModal();
      return;
    }

    try {
      await updateUserRole(userId, value);

      toast.success(
        t("settings:organisation.members.role.update.success", {
          email,
          role: getUserRoleLabel(role, t),
        }),
      );
    } catch (e) {
      toast.error(
        t("settings:organisation.members.role.update.error", {
          email,
          role: getUserRoleLabel(role, t),
        }),
      );
    }
  };

  if (userData?.userId === userId) {
    return null;
  }

  return (
    <Select value={role} onValueChange={(value) => onChange(value as UserRole)}>
      <SelectTrigger className="w-[8.75rem]" data-testid="user-role-options">
        <UserRoleLabel role={role} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options.map((option) => (
            <SelectItem key={option} value={option}>
              <UserRoleLabel role={option} />
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
