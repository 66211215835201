import { Button } from "components/button";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemaTabProps } from "./types";
import { DatabaseCodes } from "./DatabaseCodes";
import { GeneratedCodes } from "./GeneratedCode";

export const ThemaTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("themaTab") || "generated";
  const { t } = useTranslation(["productDetails"]);

  const tabs: ThemaTabProps[] = [
    {
      title: t("thema.tabs.generated"),
      key: "generated",
      content: <GeneratedCodes />,
    },
    {
      title: t("thema.tabs.database"),
      key: "database",
      content: <DatabaseCodes />,
    },
  ];

  const validActiveTab = tabs.find((tab) => tab.key === activeTab)
    ? activeTab
    : "generated";

  const handleTabClick = (tabKey: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("themaTab", tabKey);
    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex flex-col w-full gap-2 h-full">
      <div className="flex flex-row gap-2 bg-secondary-50 p-2 rounded-xl">
        {tabs.map((tab) => (
          <Button
            key={tab.title}
            label={<p className="text-s text-secondary-700">{tab.title}</p>}
            size="small"
            variant={validActiveTab === tab.key ? "tertiary" : "ghost"}
            onClick={() => handleTabClick(tab.key)}
          />
        ))}
      </div>
      {tabs.find((tab) => tab.key === validActiveTab)?.content}
    </div>
  );
};
