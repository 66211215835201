import { useTranslation } from "react-i18next";
import { Grid, GridHeader, GridTitle, GridBody } from "components/ui/grid";
import { useProductActions } from "hooks/useProductActions";
import { useErrorToast } from "hooks/useErrorToast";
import { useProduct } from "contexts/ProductContext";
import { useGenerations } from "hooks/useGenerations";
import { logError } from "shared/services/ErrorReporting";

import { BaseListContent } from "components/thema/BaseListContent";
import { Generate } from "components/svg/icons/Generate";
import { SubjectsListProps } from "./types";
import { useSubjectsData } from "./useSubjectsData";

export const SubjectsList = ({ variant = "generated" }: SubjectsListProps) => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();
  const { generateProductSubjects, errorGenerate } = useProductActions();
  const { generations } = useGenerations();

  const { selectedSubjects, isEmptyList, finalSubjects } = useSubjectsData(
    variant,
    generations,
    product,
  );

  useErrorToast(errorGenerate ? t("thema.error.generate") : undefined);

  const handleGenerate = () => {
    if (!product) {
      logError("Product not found while generating thema");
      return;
    }
    generateProductSubjects(product.id);
  };

  return (
    <Grid className="h-full">
      <GridHeader>
        <GridTitle>{t("thema.headers.subjects")}</GridTitle>
      </GridHeader>
      <GridBody className="h-full">
        <BaseListContent
          isEmptyList={isEmptyList}
          items={selectedSubjects}
          finalItems={finalSubjects}
          variant="vertical"
          emptyListProps={{
            onClick: handleGenerate,
            title:
              variant === "database"
                ? t("thema.sections.noSubjects.database.title")
                : t("thema.sections.noSubjects.generated.title"),
            description:
              variant === "database"
                ? t("thema.sections.noSubjects.database.description")
                : t("thema.sections.noSubjects.generated.description"),
            label:
              variant === "database"
                ? undefined
                : t("thema.sections.noSubjects.generated.generate"),
            icon:
              variant === "database" ? undefined : (
                <Generate className="w-5 h-5 mr-2" size={18} strokeWidth={3} />
              ),
          }}
          itemTestIdPrefix="add-subject"
        />
      </GridBody>
    </Grid>
  );
};
