import { useTranslation } from "react-i18next";
import { Button } from "../../../components/button";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/ui/tooltip";

export const ExtractButton = ({
  disabled,
  onClick,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(["general", "projectDetails"]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span>
          <Button
            onClick={onClick}
            disabled={disabled}
            label={t("general:buttons.export")}
            icon="upload"
            variant="tertiary"
            analyticsId="button_export_products"
          />
        </span>
      </TooltipTrigger>
      {disabled ? (
        <TooltipContent
          side="bottom"
          className="font-light text-center shadow-tooltip text-neutral-600 whitespace-pre px-5 z-10 border-none leading-relaxed"
          sideOffset={6}
        >
          <TooltipArrow
            className="fill-white drop-shadow-sm"
            width={12}
            height={6}
          />
          <p className="whitespace-pre">
            {t("projectDetails:buttons.export.disabledReason")}
          </p>
        </TooltipContent>
      ) : null}
    </Tooltip>
  );
};
