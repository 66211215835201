// Generated by ts-to-zod
import { z } from "zod";
import {
  UserRole,
  FirestoreGeneratedStatus,
  FirestoreGenerationStatusCode,
  relatedType,
  SubjectSource,
  status,
  model,
  productGroup,
  productGroupDescription,
} from "./types.gen";

export const firestoreOrganisationTargetGroupsSchema = z.object({
  name: z.string(),
  description: z.string(),
});

export const accountOrganisationFileResponseSchema = z.object({
  name: z.string(),
  size: z.number(),
  url: z.string(),
  date: z.date().nullable(),
});

export const accountOrganisationFilesResponseSchema = z.object({
  items: z.array(accountOrganisationFileResponseSchema),
});

export const accountOrganisationResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.date().optional().nullable(),
  emails: z.array(z.string()).optional().nullable(),
  targetGroups: z
    .array(firestoreOrganisationTargetGroupsSchema)
    .optional()
    .nullable(),
});

export const accountOrganisationUpdateRequestSchema = z.object({
  name: z.string().optional(),
  createdAt: z.date().optional().nullable(),
  emails: z.array(z.string()).optional(),
  targetGroups: z
    .array(firestoreOrganisationTargetGroupsSchema)
    .optional()
    .nullable(),
});

export const accountOrganisationsResponseSchema = z.object({
  items: z.array(accountOrganisationResponseSchema).optional(),
});

export const firestoreUserApiKeySchema = z.object({
  identifier: z.string(),
  name: z.string().optional().nullable(),
  apiKeyShort: z.string(),
  createdAt: z.date(),
});

export const accountUserApiKeyRequestSchema = z.object({
  name: z.string().optional().nullable(),
});

export const accountUserApiKeysResponseSchema = z.object({
  items: z.array(firestoreUserApiKeySchema).optional(),
});

export const accountUserCreateRequestSchema = z.object({
  email: z.string(),
  givenName: z.string().optional().nullable(),
  familyName: z.string().optional().nullable(),
});

export const firestoreOrganisationShortSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const userRoleSchema = z.nativeEnum(UserRole);

export const accountUserUpdateRequestSchema = z.object({
  email: z.string().optional(),
  givenName: z.string().optional(),
  familyName: z.string().optional(),
  roles: z.array(userRoleSchema).optional().nullable(),
});

export const firestoreUserSchema = z.object({
  id: z.string(),
  userId: z.string(),
  email: z.string(),
  emailVerified: z.boolean().optional(),
  givenName: z.string().optional().nullable(),
  familyName: z.string().optional().nullable(),
  organisation: firestoreOrganisationShortSchema.optional().nullable(),
  apiKeys: z.array(firestoreUserApiKeySchema).optional().nullable(),
  roles: z.array(userRoleSchema).optional().nullable(),
});

export const apiResponseAccountOrganisationFileResponseSchema = z.object({
  success: z.boolean().optional(),
  data: accountOrganisationFileResponseSchema,
  code: z.number().optional(),
});

export const apiResponseAccountOrganisationFilesResponseSchema = z.object({
  success: z.boolean().optional(),
  data: accountOrganisationFilesResponseSchema,
  code: z.number().optional(),
});

export const apiResponseAccountOrganisationResponseSchema = z.object({
  success: z.boolean().optional(),
  data: accountOrganisationResponseSchema,
  code: z.number().optional(),
});

export const apiResponseAccountOrganisationsResponseSchema = z.object({
  success: z.boolean().optional(),
  data: accountOrganisationsResponseSchema,
  code: z.number().optional(),
});

export const accountUserApiKeyCreatedResponseSchema = z.object({
  api_key: z.string(),
  user_api_key: firestoreUserApiKeySchema,
});

export const apiResponseAccountUserApiKeysResponseSchema = z.object({
  success: z.boolean().optional(),
  data: accountUserApiKeysResponseSchema,
  code: z.number().optional(),
});

export const accountUserResponseSchema = z.object({
  id: z.string(),
  email: z.string(),
  givenName: z.string().optional().nullable(),
  familyName: z.string().optional().nullable(),
  organisation: firestoreOrganisationShortSchema.optional().nullable(),
  roles: z.array(userRoleSchema).optional().nullable(),
});

export const accountUsersResponseSchema = z.object({
  items: z.array(firestoreUserSchema).optional(),
});

export const apiResponseMessageSchema = z.object({
  message: z.string(),
});

export const productsExportResponseSchema = z.object({
  id: z.string(),
  organisation: firestoreOrganisationShortSchema,
  productIds: z.array(z.string()).optional(),
  status: z.union([
    z.literal("REQUESTED"),
    z.literal("COMPLETED"),
    z.literal("ERROR"),
  ]),
  userId: z.string(),
  filePath: z.string().optional().nullable(),
});

export const firestoreGeneratedStatusSchema = z.nativeEnum(
  FirestoreGeneratedStatus,
);

export const firestoreGenerationStatusCodeSchema = z.nativeEnum(
  FirestoreGenerationStatusCode,
);

export const firestoreProductGeneratedDataKeywordsSchema = z.object({
  data: z.array(z.string()).optional(),
  status: firestoreGeneratedStatusSchema.optional(),
  statusCode: firestoreGenerationStatusCodeSchema.optional().nullable(),
  userId: z.string().optional(),
  autoFill: z.boolean().optional().nullable(),
});

export const firestoreProductGeneratedDataDescriptionDataSchema = z.object({
  details: z.string().optional().nullable(),
  isManualInput: z.boolean().optional(),
  target: z.string().optional().nullable(),
  text: z.string().optional().nullable(),
});

export const firestoreProductImportDataSchema = z.object({
  importId: z.string(),
  productsImportId: z.string(),
  objectName: z.string(),
  folderName: z.string(),
  fileName: z.string(),
});

export const firestoreProductRelatedProductIdentifierSchema = z.object({
  idValue: z.string(),
  productIDType: z.string(),
  productIDTypeName: z.string().optional().nullable(),
});

export const relatedTypeSchema = z.nativeEnum(relatedType);

export const subjectSourceSchema = z.nativeEnum(SubjectSource);

export const firestoreProductSubjectSchema = z
  .record(z.union([z.unknown(), z.string()]))
  .and(
    z.object({
      id: z.string().optional(),
      responseId: z.string().optional().nullable(),
      source: subjectSourceSchema.optional().nullable(),
      subjectCode: z.string().optional().nullable(),
      subjectHeadingText: z.string().optional().nullable(),
      reasoning: z.string().optional().nullable(),
      subjectSchemeIdentifier: z.string().optional().nullable(),
      ranking: z.number().optional().nullable(),
      rankingReasoning: z.string().optional().nullable(),
    }),
  );

export const metadataBlurbRequestSchema = z.object({
  inputText: z.string(),
  language: z.string().optional().nullable(),
  theme: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  author: z.array(z.string()).optional().nullable(),
  publisher: z.string().optional().nullable(),
  format: z
    .array(
      z.union([
        z.literal("A101"),
        z.literal("A102"),
        z.literal("A103"),
        z.literal("A104"),
        z.literal("A105"),
        z.literal("A106"),
        z.literal("A107"),
        z.literal("A108"),
        z.literal("A109"),
        z.literal("A110"),
        z.literal("A111"),
        z.literal("A112"),
        z.literal("A113"),
        z.literal("A201"),
        z.literal("A202"),
        z.literal("A203"),
        z.literal("A204"),
        z.literal("A205"),
        z.literal("A206"),
        z.literal("A207"),
        z.literal("A208"),
        z.literal("A209"),
        z.literal("A210"),
        z.literal("A211"),
        z.literal("A212"),
        z.literal("A301"),
        z.literal("A302"),
        z.literal("A303"),
        z.literal("A304"),
        z.literal("A305"),
        z.literal("A310"),
        z.literal("A311"),
        z.literal("A312"),
        z.literal("A400"),
        z.literal("A401"),
        z.literal("A402"),
        z.literal("A403"),
        z.literal("A404"),
        z.literal("A410"),
        z.literal("A420"),
        z.literal("A421"),
        z.literal("A441"),
        z.literal("A451"),
        z.literal("A471"),
        z.literal("B101"),
        z.literal("B102"),
        z.literal("B103"),
        z.literal("B104"),
        z.literal("B105"),
        z.literal("B106"),
        z.literal("B107"),
        z.literal("B108"),
        z.literal("B109"),
        z.literal("B110"),
        z.literal("B111"),
        z.literal("B112"),
        z.literal("B113"),
        z.literal("B114"),
        z.literal("B115"),
        z.literal("B116"),
        z.literal("B117"),
        z.literal("B118"),
        z.literal("B119"),
        z.literal("B120"),
        z.literal("B121"),
        z.literal("B122"),
        z.literal("B123"),
        z.literal("B124"),
        z.literal("B125"),
        z.literal("B126"),
        z.literal("B127"),
        z.literal("B128"),
        z.literal("B129"),
        z.literal("B130"),
        z.literal("B131"),
        z.literal("B132"),
        z.literal("B133"),
        z.literal("B134"),
        z.literal("B135"),
        z.literal("B201"),
        z.literal("B202"),
        z.literal("B204"),
        z.literal("B205"),
        z.literal("B206"),
        z.literal("B207"),
        z.literal("B208"),
        z.literal("B209"),
        z.literal("B210"),
        z.literal("B212"),
        z.literal("B213"),
        z.literal("B214"),
        z.literal("B215"),
        z.literal("B216"),
        z.literal("B221"),
        z.literal("B222"),
        z.literal("B223"),
        z.literal("B224"),
        z.literal("B301"),
        z.literal("B302"),
        z.literal("B303"),
        z.literal("B304"),
        z.literal("B305"),
        z.literal("B306"),
        z.literal("B307"),
        z.literal("B308"),
        z.literal("B309"),
        z.literal("B310"),
        z.literal("B311"),
        z.literal("B312"),
        z.literal("B313"),
        z.literal("B314"),
        z.literal("B315"),
        z.literal("B316"),
        z.literal("B317"),
        z.literal("B318"),
        z.literal("B400"),
        z.literal("B401"),
        z.literal("B402"),
        z.literal("B403"),
        z.literal("B404"),
        z.literal("B405"),
        z.literal("B406"),
        z.literal("B409"),
        z.literal("B410"),
        z.literal("B411"),
        z.literal("B412"),
        z.literal("B413"),
        z.literal("B414"),
        z.literal("B415"),
        z.literal("B416"),
        z.literal("B417"),
        z.literal("B420"),
        z.literal("B421"),
        z.literal("B422"),
        z.literal("B423"),
        z.literal("B424"),
        z.literal("B425"),
        z.literal("B426"),
        z.literal("B427"),
        z.literal("B428"),
        z.literal("B501"),
        z.literal("B502"),
        z.literal("B503"),
        z.literal("B504"),
        z.literal("B505"),
        z.literal("B506"),
        z.literal("B507"),
        z.literal("B508"),
        z.literal("B509"),
        z.literal("B510"),
        z.literal("B511"),
        z.literal("B512"),
        z.literal("B513"),
        z.literal("B514"),
        z.literal("B515"),
        z.literal("B516"),
        z.literal("B517"),
        z.literal("B518"),
        z.literal("B519"),
        z.literal("B520"),
        z.literal("B601"),
        z.literal("B602"),
        z.literal("B603"),
        z.literal("B604"),
        z.literal("B605"),
        z.literal("B606"),
        z.literal("B607"),
        z.literal("B608"),
        z.literal("B609"),
        z.literal("B610"),
        z.literal("B611"),
        z.literal("B701"),
        z.literal("B702"),
        z.literal("B703"),
        z.literal("B704"),
        z.literal("B705"),
        z.literal("B706"),
        z.literal("B707"),
        z.literal("B708"),
        z.literal("B709"),
        z.literal("B721"),
        z.literal("B722"),
        z.literal("B732"),
        z.literal("B750"),
        z.literal("B751"),
        z.literal("B752"),
        z.literal("B753"),
        z.literal("C750"),
        z.literal("C751"),
        z.literal("D101"),
        z.literal("D102"),
        z.literal("D103"),
        z.literal("D104"),
        z.literal("D105"),
        z.literal("D201"),
        z.literal("D202"),
        z.literal("D203"),
        z.literal("D204"),
        z.literal("D205"),
        z.literal("D206"),
        z.literal("D207"),
        z.literal("D301"),
        z.literal("D302"),
        z.literal("D303"),
        z.literal("D304"),
        z.literal("D305"),
        z.literal("D306"),
        z.literal("D307"),
        z.literal("D308"),
        z.literal("D309"),
        z.literal("D310"),
        z.literal("D311"),
        z.literal("D312"),
        z.literal("D313"),
        z.literal("D315"),
        z.literal("D316"),
        z.literal("D318"),
        z.literal("D319"),
        z.literal("D320"),
        z.literal("D321"),
        z.literal("D322"),
        z.literal("D323"),
        z.literal("D324"),
        z.literal("E100"),
        z.literal("E101"),
        z.literal("E102"),
        z.literal("E103"),
        z.literal("E104"),
        z.literal("E105"),
        z.literal("E106"),
        z.literal("E107"),
        z.literal("E108"),
        z.literal("E109"),
        z.literal("E110"),
        z.literal("E111"),
        z.literal("E112"),
        z.literal("E113"),
        z.literal("E114"),
        z.literal("E115"),
        z.literal("E116"),
        z.literal("E117"),
        z.literal("E118"),
        z.literal("E119"),
        z.literal("E120"),
        z.literal("E121"),
        z.literal("E122"),
        z.literal("E123"),
        z.literal("E124"),
        z.literal("E125"),
        z.literal("E126"),
        z.literal("E127"),
        z.literal("E128"),
        z.literal("E129"),
        z.literal("E130"),
        z.literal("E131"),
        z.literal("E132"),
        z.literal("E133"),
        z.literal("E134"),
        z.literal("E135"),
        z.literal("E136"),
        z.literal("E139"),
        z.literal("E140"),
        z.literal("E141"),
        z.literal("E142"),
        z.literal("E143"),
        z.literal("E144"),
        z.literal("E145"),
        z.literal("E146"),
        z.literal("E147"),
        z.literal("E148"),
        z.literal("E149"),
        z.literal("E200"),
        z.literal("E201"),
        z.literal("E202"),
        z.literal("E203"),
        z.literal("E204"),
        z.literal("E205"),
        z.literal("E206"),
        z.literal("E210"),
        z.literal("E211"),
        z.literal("E212"),
        z.literal("E213"),
        z.literal("E221"),
        z.literal("E222"),
        z.literal("E223"),
        z.literal("E224"),
        z.literal("E225"),
        z.literal("E226"),
        z.literal("E227"),
        z.literal("L101"),
        z.literal("P091"),
        z.literal("P092"),
        z.literal("P096"),
        z.literal("P097"),
        z.literal("P098"),
        z.literal("P099"),
        z.literal("P101"),
        z.literal("P102"),
        z.literal("P103"),
        z.literal("P104"),
        z.literal("P105"),
        z.literal("P106"),
        z.literal("P107"),
        z.literal("P108"),
        z.literal("P109"),
        z.literal("P110"),
        z.literal("P111"),
        z.literal("P112"),
        z.literal("P113"),
        z.literal("P114"),
        z.literal("P115"),
        z.literal("P116"),
        z.literal("P131"),
        z.literal("P132"),
        z.literal("P133"),
        z.literal("P120"),
        z.literal("P121"),
        z.literal("P122"),
        z.literal("P123"),
        z.literal("P124"),
        z.literal("P125"),
        z.literal("P126"),
        z.literal("P127"),
        z.literal("P201"),
        z.literal("P202"),
        z.literal("P203"),
        z.literal("P204"),
        z.literal("P301"),
        z.literal("P305"),
        z.literal("P306"),
        z.literal("V201"),
        z.literal("V202"),
        z.literal("V203"),
        z.literal("V205"),
        z.literal("V206"),
        z.literal("V207"),
        z.literal("V220"),
        z.literal("V221"),
        z.literal("Z101"),
        z.literal("Z102"),
        z.literal("Z103"),
        z.literal("Z111"),
        z.literal("Z112"),
        z.literal("Z113"),
        z.literal("Z121"),
      ]),
    )
    .optional()
    .nullable(),
  genre: z.array(z.string()).optional().nullable(),
  keywords: z.array(z.string()).optional().nullable(),
  specialRequest: z.string().optional().nullable(),
  targetGroup: z.string().optional().nullable(),
  targetGroupBook: z.string().optional().nullable(),
  maxLength: z.number().optional().nullable(),
  additionalData: z.unknown().optional().nullable(),
});

export const metadataBlurbResponseDataSchema = z.object({
  identifier: z.string(),
  generatedText: z.string(),
});

export const metadataKeywordRequestSchema = z.object({
  inputText: z.string(),
  language: z.string().optional().nullable(),
  theme: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  author: z.array(z.string()).optional().nullable(),
  publisher: z.string().optional().nullable(),
  format: z
    .array(
      z.union([
        z.literal("A101"),
        z.literal("A102"),
        z.literal("A103"),
        z.literal("A104"),
        z.literal("A105"),
        z.literal("A106"),
        z.literal("A107"),
        z.literal("A108"),
        z.literal("A109"),
        z.literal("A110"),
        z.literal("A111"),
        z.literal("A112"),
        z.literal("A113"),
        z.literal("A201"),
        z.literal("A202"),
        z.literal("A203"),
        z.literal("A204"),
        z.literal("A205"),
        z.literal("A206"),
        z.literal("A207"),
        z.literal("A208"),
        z.literal("A209"),
        z.literal("A210"),
        z.literal("A211"),
        z.literal("A212"),
        z.literal("A301"),
        z.literal("A302"),
        z.literal("A303"),
        z.literal("A304"),
        z.literal("A305"),
        z.literal("A310"),
        z.literal("A311"),
        z.literal("A312"),
        z.literal("A400"),
        z.literal("A401"),
        z.literal("A402"),
        z.literal("A403"),
        z.literal("A404"),
        z.literal("A410"),
        z.literal("A420"),
        z.literal("A421"),
        z.literal("A441"),
        z.literal("A451"),
        z.literal("A471"),
        z.literal("B101"),
        z.literal("B102"),
        z.literal("B103"),
        z.literal("B104"),
        z.literal("B105"),
        z.literal("B106"),
        z.literal("B107"),
        z.literal("B108"),
        z.literal("B109"),
        z.literal("B110"),
        z.literal("B111"),
        z.literal("B112"),
        z.literal("B113"),
        z.literal("B114"),
        z.literal("B115"),
        z.literal("B116"),
        z.literal("B117"),
        z.literal("B118"),
        z.literal("B119"),
        z.literal("B120"),
        z.literal("B121"),
        z.literal("B122"),
        z.literal("B123"),
        z.literal("B124"),
        z.literal("B125"),
        z.literal("B126"),
        z.literal("B127"),
        z.literal("B128"),
        z.literal("B129"),
        z.literal("B130"),
        z.literal("B131"),
        z.literal("B132"),
        z.literal("B133"),
        z.literal("B134"),
        z.literal("B135"),
        z.literal("B201"),
        z.literal("B202"),
        z.literal("B204"),
        z.literal("B205"),
        z.literal("B206"),
        z.literal("B207"),
        z.literal("B208"),
        z.literal("B209"),
        z.literal("B210"),
        z.literal("B212"),
        z.literal("B213"),
        z.literal("B214"),
        z.literal("B215"),
        z.literal("B216"),
        z.literal("B221"),
        z.literal("B222"),
        z.literal("B223"),
        z.literal("B224"),
        z.literal("B301"),
        z.literal("B302"),
        z.literal("B303"),
        z.literal("B304"),
        z.literal("B305"),
        z.literal("B306"),
        z.literal("B307"),
        z.literal("B308"),
        z.literal("B309"),
        z.literal("B310"),
        z.literal("B311"),
        z.literal("B312"),
        z.literal("B313"),
        z.literal("B314"),
        z.literal("B315"),
        z.literal("B316"),
        z.literal("B317"),
        z.literal("B318"),
        z.literal("B400"),
        z.literal("B401"),
        z.literal("B402"),
        z.literal("B403"),
        z.literal("B404"),
        z.literal("B405"),
        z.literal("B406"),
        z.literal("B409"),
        z.literal("B410"),
        z.literal("B411"),
        z.literal("B412"),
        z.literal("B413"),
        z.literal("B414"),
        z.literal("B415"),
        z.literal("B416"),
        z.literal("B417"),
        z.literal("B420"),
        z.literal("B421"),
        z.literal("B422"),
        z.literal("B423"),
        z.literal("B424"),
        z.literal("B425"),
        z.literal("B426"),
        z.literal("B427"),
        z.literal("B428"),
        z.literal("B501"),
        z.literal("B502"),
        z.literal("B503"),
        z.literal("B504"),
        z.literal("B505"),
        z.literal("B506"),
        z.literal("B507"),
        z.literal("B508"),
        z.literal("B509"),
        z.literal("B510"),
        z.literal("B511"),
        z.literal("B512"),
        z.literal("B513"),
        z.literal("B514"),
        z.literal("B515"),
        z.literal("B516"),
        z.literal("B517"),
        z.literal("B518"),
        z.literal("B519"),
        z.literal("B520"),
        z.literal("B601"),
        z.literal("B602"),
        z.literal("B603"),
        z.literal("B604"),
        z.literal("B605"),
        z.literal("B606"),
        z.literal("B607"),
        z.literal("B608"),
        z.literal("B609"),
        z.literal("B610"),
        z.literal("B611"),
        z.literal("B701"),
        z.literal("B702"),
        z.literal("B703"),
        z.literal("B704"),
        z.literal("B705"),
        z.literal("B706"),
        z.literal("B707"),
        z.literal("B708"),
        z.literal("B709"),
        z.literal("B721"),
        z.literal("B722"),
        z.literal("B732"),
        z.literal("B750"),
        z.literal("B751"),
        z.literal("B752"),
        z.literal("B753"),
        z.literal("C750"),
        z.literal("C751"),
        z.literal("D101"),
        z.literal("D102"),
        z.literal("D103"),
        z.literal("D104"),
        z.literal("D105"),
        z.literal("D201"),
        z.literal("D202"),
        z.literal("D203"),
        z.literal("D204"),
        z.literal("D205"),
        z.literal("D206"),
        z.literal("D207"),
        z.literal("D301"),
        z.literal("D302"),
        z.literal("D303"),
        z.literal("D304"),
        z.literal("D305"),
        z.literal("D306"),
        z.literal("D307"),
        z.literal("D308"),
        z.literal("D309"),
        z.literal("D310"),
        z.literal("D311"),
        z.literal("D312"),
        z.literal("D313"),
        z.literal("D315"),
        z.literal("D316"),
        z.literal("D318"),
        z.literal("D319"),
        z.literal("D320"),
        z.literal("D321"),
        z.literal("D322"),
        z.literal("D323"),
        z.literal("D324"),
        z.literal("E100"),
        z.literal("E101"),
        z.literal("E102"),
        z.literal("E103"),
        z.literal("E104"),
        z.literal("E105"),
        z.literal("E106"),
        z.literal("E107"),
        z.literal("E108"),
        z.literal("E109"),
        z.literal("E110"),
        z.literal("E111"),
        z.literal("E112"),
        z.literal("E113"),
        z.literal("E114"),
        z.literal("E115"),
        z.literal("E116"),
        z.literal("E117"),
        z.literal("E118"),
        z.literal("E119"),
        z.literal("E120"),
        z.literal("E121"),
        z.literal("E122"),
        z.literal("E123"),
        z.literal("E124"),
        z.literal("E125"),
        z.literal("E126"),
        z.literal("E127"),
        z.literal("E128"),
        z.literal("E129"),
        z.literal("E130"),
        z.literal("E131"),
        z.literal("E132"),
        z.literal("E133"),
        z.literal("E134"),
        z.literal("E135"),
        z.literal("E136"),
        z.literal("E139"),
        z.literal("E140"),
        z.literal("E141"),
        z.literal("E142"),
        z.literal("E143"),
        z.literal("E144"),
        z.literal("E145"),
        z.literal("E146"),
        z.literal("E147"),
        z.literal("E148"),
        z.literal("E149"),
        z.literal("E200"),
        z.literal("E201"),
        z.literal("E202"),
        z.literal("E203"),
        z.literal("E204"),
        z.literal("E205"),
        z.literal("E206"),
        z.literal("E210"),
        z.literal("E211"),
        z.literal("E212"),
        z.literal("E213"),
        z.literal("E221"),
        z.literal("E222"),
        z.literal("E223"),
        z.literal("E224"),
        z.literal("E225"),
        z.literal("E226"),
        z.literal("E227"),
        z.literal("L101"),
        z.literal("P091"),
        z.literal("P092"),
        z.literal("P096"),
        z.literal("P097"),
        z.literal("P098"),
        z.literal("P099"),
        z.literal("P101"),
        z.literal("P102"),
        z.literal("P103"),
        z.literal("P104"),
        z.literal("P105"),
        z.literal("P106"),
        z.literal("P107"),
        z.literal("P108"),
        z.literal("P109"),
        z.literal("P110"),
        z.literal("P111"),
        z.literal("P112"),
        z.literal("P113"),
        z.literal("P114"),
        z.literal("P115"),
        z.literal("P116"),
        z.literal("P131"),
        z.literal("P132"),
        z.literal("P133"),
        z.literal("P120"),
        z.literal("P121"),
        z.literal("P122"),
        z.literal("P123"),
        z.literal("P124"),
        z.literal("P125"),
        z.literal("P126"),
        z.literal("P127"),
        z.literal("P201"),
        z.literal("P202"),
        z.literal("P203"),
        z.literal("P204"),
        z.literal("P301"),
        z.literal("P305"),
        z.literal("P306"),
        z.literal("V201"),
        z.literal("V202"),
        z.literal("V203"),
        z.literal("V205"),
        z.literal("V206"),
        z.literal("V207"),
        z.literal("V220"),
        z.literal("V221"),
        z.literal("Z101"),
        z.literal("Z102"),
        z.literal("Z103"),
        z.literal("Z111"),
        z.literal("Z112"),
        z.literal("Z113"),
        z.literal("Z121"),
      ]),
    )
    .optional()
    .nullable(),
  genre: z.array(z.string()).optional().nullable(),
  keywords: z.array(z.string()).optional().nullable(),
  specialRequest: z.string().optional().nullable(),
  targetGroup: z.string().optional().nullable(),
  targetGroupBook: z.string().optional().nullable(),
  maxLength: z.number().optional().nullable(),
  additionalData: z.unknown().optional().nullable(),
});

export const metadataKeywordResponseDataSchema = z.object({
  identifier: z.string(),
  generatedKeywords: z.array(z.string()),
});

export const organisationUserCreateRequestSchema = z.object({
  email: z.string(),
  role: userRoleSchema,
});

export const organisationUsersCreateRequestSchema = z.object({
  users: z.array(organisationUserCreateRequestSchema).optional(),
});

export const productsExportRequestSchema = z.object({
  productIds: z.array(z.string()),
});

export const statusSchema = z.nativeEnum(status);

export const searchQueryRequestSchema = z
  .record(z.union([z.unknown(), z.string(), z.number()]))
  .and(
    z.object({
      model: z.literal("products"),
      q: z.string(),
      query_by: z.string(),
      filter_by: z.string().optional().nullable(),
      sort_by: z.string().optional().nullable(),
      page: z.number().optional(),
      per_page: z.number().optional(),
      offset: z.number().optional().nullable(),
      limit: z.number().optional().nullable(),
      prefix: z.string().optional().nullable(),
      infix: z.string().optional().nullable(),
    }),
  );

export const modelSchema = z.nativeEnum(model);

export const searchQueryResponseRequestParamsSchema = z
  .record(z.union([z.unknown(), z.string(), z.number()]))
  .and(
    z.object({
      collection_name: z.string(),
      first_q: z.string(),
      per_page: z.number(),
      q: z.string(),
    }),
  );

export const searchQueryResponseHitTextMatchInfoSchema = z.object({
  best_field_score: z.string(),
  best_field_weight: z.number(),
  fields_matched: z.number(),
  num_tokens_dropped: z.number(),
  score: z.string(),
  tokens_matched: z.number(),
  typo_prefix_score: z.number(),
});

export const firestoreProductRelatedProductSchema = z.object({
  identifier: z.string(),
  identifierType: z.string(),
  relatedType: z.union([z.literal("PRODUCT"), z.literal("WORK")]),
  relationCodes: z.array(z.string()),
  identifiers: z
    .array(firestoreProductRelatedProductIdentifierSchema)
    .optional()
    .nullable(),
  form: z.string().optional().nullable(),
});

export const firestoreProductSubjectsSchema = z.record(z.unknown()).and(
  z.object({
    generated: z.array(firestoreProductSubjectSchema).optional().nullable(),
    database: z.array(firestoreProductSubjectSchema).optional().nullable(),
    final: z.array(firestoreProductSubjectSchema).optional().nullable(),
  }),
);

export const productGroupSchema = z.nativeEnum(productGroup);

export const productGroupDescriptionSchema = z.nativeEnum(
  productGroupDescription,
);

export const apiResponseApiResponseMessageSchema = z.object({
  success: z.boolean().optional(),
  data: apiResponseMessageSchema,
  code: z.number().optional(),
});

export const healthErrorSchema = z.unknown();

export const searchDataSchema = z.object({
  body: searchQueryRequestSchema.optional(),
});

export const searchErrorSchema = z.unknown();

export const postBlurbDataSchema = z.object({
  body: metadataBlurbRequestSchema.optional(),
});

export const postBlurbErrorSchema = z.unknown();

export const getBlurbDataSchema = z.object({
  path: z.object({
    identifier: z.string(),
  }),
});

export const getBlurbErrorSchema = z.unknown();

export const postKeywordsDataSchema = z.object({
  body: metadataKeywordRequestSchema.optional(),
});

export const postKeywordsErrorSchema = z.unknown();

export const getKeywordsDataSchema = z.object({
  path: z.object({
    identifier: z.string(),
  }),
});

export const getKeywordsErrorSchema = z.unknown();

export const getOrganisationsResponseSchema =
  apiResponseAccountOrganisationsResponseSchema;

export const getOrganisationsErrorSchema = z.unknown();

export const getOrganisationDataSchema = z.object({
  path: z.object({
    identifier: z.string(),
  }),
});

export const getOrganisationResponseSchema =
  apiResponseAccountOrganisationResponseSchema;

export const getOrganisationErrorSchema = z.unknown();

export const patchOrganisationDataSchema = z.object({
  body: accountOrganisationUpdateRequestSchema.optional(),
  path: z.object({
    identifier: z.string(),
  }),
});

export const patchOrganisationResponseSchema =
  apiResponseAccountOrganisationResponseSchema;

export const patchOrganisationErrorSchema = z.unknown();

export const accountOrganisationCreateRequestSchema = z.object({
  name: z.string(),
  createdAt: z.date().optional().nullable(),
  emails: z.array(z.string()).optional().nullable(),
  targetGroups: z
    .array(firestoreOrganisationTargetGroupsSchema)
    .optional()
    .nullable(),
});

export const postOrganisationResponseSchema =
  apiResponseAccountOrganisationResponseSchema;

export const postOrganisationErrorSchema = z.unknown();

export const getOrganisationsUsersDataSchema = z.object({
  path: z.object({
    identifier: z.string(),
  }),
});

export const apiResponseAccountUsersResponseSchema = z.object({
  success: z.boolean().optional(),
  data: accountUsersResponseSchema,
  code: z.number().optional(),
});

export const getOrganisationsUsersErrorSchema = z.unknown();

export const addOrganisationUsersDataSchema = z.object({
  body: organisationUsersCreateRequestSchema.optional(),
  path: z.object({
    identifier: z.string(),
  }),
});

export const addOrganisationUsersResponseSchema =
  apiResponseApiResponseMessageSchema;

export const addOrganisationUsersErrorSchema = z.unknown();

export const postUserDataSchema = z.object({
  body: accountUserCreateRequestSchema.optional(),
  path: z.object({
    identifier: z.string(),
  }),
});

export const apiResponseAccountUserResponseSchema = z.object({
  success: z.boolean().optional(),
  data: accountUserResponseSchema,
  code: z.number().optional(),
});

export const postUserErrorSchema = z.unknown();

export const getUserDataSchema = z.object({
  path: z.object({
    identifier: z.string(),
    useridentifier: z.string(),
  }),
});

export const getUserResponseSchema = apiResponseAccountUserResponseSchema;

export const getUserErrorSchema = z.unknown();

export const deleteOrganisationUserDataSchema = z.object({
  path: z.object({
    identifier: z.string(),
    useridentifier: z.string(),
  }),
});

export const deleteOrganisationUserResponseSchema =
  apiResponseApiResponseMessageSchema;

export const deleteOrganisationUserErrorSchema = z.unknown();

export const patchUserDataSchema = z.object({
  body: accountUserUpdateRequestSchema.optional(),
  path: z.object({
    identifier: z.string(),
    useridentifier: z.string(),
  }),
});

export const patchUserResponseSchema = apiResponseAccountUserResponseSchema;

export const patchUserErrorSchema = z.unknown();

export const getOrganisationUserApiKeysDataSchema = z.object({
  path: z.object({
    identifier: z.string(),
    useridentifier: z.string(),
  }),
});

export const getOrganisationUserApiKeysResponseSchema =
  apiResponseAccountUserApiKeysResponseSchema;

export const getOrganisationUserApiKeysErrorSchema = z.unknown();

export const postOrganisationUserApiKeyDataSchema = z.object({
  body: accountUserApiKeyRequestSchema.optional(),
  path: z.object({
    identifier: z.string(),
    useridentifier: z.string(),
  }),
});

export const apiResponseAccountUserApiKeyCreatedResponseSchema = z.object({
  success: z.boolean().optional(),
  data: accountUserApiKeyCreatedResponseSchema,
  code: z.number().optional(),
});

export const postOrganisationUserApiKeyErrorSchema = z.unknown();

export const deleteOrganisationUsersApiKeyDataSchema = z.object({
  path: z.object({
    apikeyidentifier: z.string(),
    identifier: z.string(),
    useridentifier: z.string(),
  }),
});

export const deleteOrganisationUsersApiKeyResponseSchema =
  apiResponseApiResponseMessageSchema;

export const deleteOrganisationUsersApiKeyErrorSchema = z.unknown();

export const getOrganisationFilesRouteDataSchema = z.object({
  path: z.object({
    identifier: z.string(),
  }),
});

export const getOrganisationFilesRouteResponseSchema =
  apiResponseAccountOrganisationFilesResponseSchema;

export const getOrganisationFilesRouteErrorSchema = z.unknown();

export const getOrganisationFileRouteDataSchema = z.object({
  path: z.object({
    file_name: z.string(),
    identifier: z.string(),
  }),
});

export const getOrganisationFileRouteResponseSchema =
  apiResponseAccountOrganisationFileResponseSchema;

export const getOrganisationFileRouteErrorSchema = z.unknown();

export const postOrganisationFileRouteResponseSchema =
  apiResponseAccountOrganisationFileResponseSchema;

export const postOrganisationFileRouteErrorSchema = z.unknown();

export const exportProductsDataSchema = z.object({
  body: productsExportRequestSchema.optional(),
});

export const apiResponseProductsExportResponseSchema = z.object({
  success: z.boolean().optional(),
  data: productsExportResponseSchema,
  code: z.number().optional(),
});

export const exportProductsErrorSchema = z.unknown();

export const getOrganisationUserApiKeysResponseTransformerSchema = z
  .function()
  .args(z.any())
  .returns(z.promise(getOrganisationUserApiKeysResponseSchema));

export const apiResponseAccountUserApiKeysResponseModelResponseTransformerSchema =
  z
    .function()
    .args(z.any())
    .returns(apiResponseAccountUserApiKeysResponseSchema);

export const accountUserApiKeysResponseModelResponseTransformerSchema = z
  .function()
  .args(z.any())
  .returns(accountUserApiKeysResponseSchema);

export const firestoreUserApiKeyModelResponseTransformerSchema = z
  .function()
  .args(z.any())
  .returns(firestoreUserApiKeySchema);

export const apiResponseAccountUserApiKeyCreatedResponseModelResponseTransformerSchema =
  z
    .function()
    .args(z.any())
    .returns(apiResponseAccountUserApiKeyCreatedResponseSchema);

export const accountUserApiKeyCreatedResponseModelResponseTransformerSchema = z
  .function()
  .args(z.any())
  .returns(accountUserApiKeyCreatedResponseSchema);

export const metadataBlurbResponseSchema = z.object({
  generation_finished: z.boolean(),
  message: z.string().optional().nullable(),
  metadata: metadataBlurbResponseDataSchema,
});

export const metadataKeywordResponseSchema = z.object({
  generation_finished: z.boolean(),
  message: z.string().optional().nullable(),
  metadata: metadataKeywordResponseDataSchema,
});

export const firestoreProductGeneratedDataDescriptionSchema = z.object({
  data: firestoreProductGeneratedDataDescriptionDataSchema,
  status: firestoreGeneratedStatusSchema.optional(),
  statusCode: firestoreGenerationStatusCodeSchema.optional().nullable(),
  userId: z.string().optional(),
});

export const firestoreProductGeneratedDataSchema = z.object({
  keywords: firestoreProductGeneratedDataKeywordsSchema,
  description: firestoreProductGeneratedDataDescriptionSchema,
});

export const healthResponseSchema = apiResponseApiResponseMessageSchema;

export const apiResponseMetadataBlurbResponseSchema = z.object({
  success: z.boolean().optional(),
  data: metadataBlurbResponseSchema,
  code: z.number().optional(),
});

export const getBlurbResponseSchema = apiResponseMetadataBlurbResponseSchema;

export const apiResponseMetadataKeywordResponseSchema = z.object({
  success: z.boolean().optional(),
  data: metadataKeywordResponseSchema,
  code: z.number().optional(),
});

export const getKeywordsResponseSchema =
  apiResponseMetadataKeywordResponseSchema;

export const postOrganisationDataSchema = z.object({
  body: accountOrganisationCreateRequestSchema.optional(),
});

export const getOrganisationsUsersResponseSchema =
  apiResponseAccountUsersResponseSchema;

export const postUserResponseSchema = apiResponseAccountUserResponseSchema;

export const postOrganisationUserApiKeyResponseSchema =
  apiResponseAccountUserApiKeyCreatedResponseSchema;

export const exportProductsResponseSchema =
  apiResponseProductsExportResponseSchema;

export const postOrganisationUserApiKeyResponseTransformerSchema = z
  .function()
  .args(z.any())
  .returns(z.promise(postOrganisationUserApiKeyResponseSchema));

export const typesenseProductSchema = z.object({
  id: z.string().optional().nullable(),
  identifier: z.string(),
  identifierType: z.string().optional(),
  bundleIndentifiers: z.array(z.string()).optional().nullable(),
  importData: firestoreProductImportDataSchema.optional().nullable(),
  generated: firestoreProductGeneratedDataSchema,
  title: z.string(),
  summary: z.string().optional().nullable(),
  shortDescription: z.string().optional().nullable(),
  biography: z.string().optional().nullable(),
  author: z.array(z.string()).optional(),
  genre: z.array(z.string()).optional(),
  keywords: z.array(z.string()).optional(),
  keywordCount: z.number().optional(),
  finalKeywords: z.array(z.string()).optional(),
  language: z.union([
    z.literal("aar"),
    z.literal("abk"),
    z.literal("ace"),
    z.literal("ach"),
    z.literal("ada"),
    z.literal("ady"),
    z.literal("afa"),
    z.literal("afh"),
    z.literal("afr"),
    z.literal("ain"),
    z.literal("aka"),
    z.literal("akk"),
    z.literal("alb"),
    z.literal("sqi"),
    z.literal("ale"),
    z.literal("alg"),
    z.literal("alt"),
    z.literal("amh"),
    z.literal("ang"),
    z.literal("anp"),
    z.literal("apa"),
    z.literal("ara"),
    z.literal("arc"),
    z.literal("arg"),
    z.literal("arm"),
    z.literal("hye"),
    z.literal("arn"),
    z.literal("arp"),
    z.literal("art"),
    z.literal("arw"),
    z.literal("asm"),
    z.literal("ast"),
    z.literal("ath"),
    z.literal("aus"),
    z.literal("ava"),
    z.literal("ave"),
    z.literal("awa"),
    z.literal("aym"),
    z.literal("aze"),
    z.literal("bad"),
    z.literal("bai"),
    z.literal("bak"),
    z.literal("bal"),
    z.literal("bam"),
    z.literal("ban"),
    z.literal("baq"),
    z.literal("eus"),
    z.literal("bas"),
    z.literal("bat"),
    z.literal("bej"),
    z.literal("bel"),
    z.literal("bem"),
    z.literal("ben"),
    z.literal("ber"),
    z.literal("bho"),
    z.literal("bih"),
    z.literal("bik"),
    z.literal("bin"),
    z.literal("bis"),
    z.literal("bla"),
    z.literal("bnt"),
    z.literal("tib"),
    z.literal("bod"),
    z.literal("bos"),
    z.literal("bra"),
    z.literal("bre"),
    z.literal("btk"),
    z.literal("bua"),
    z.literal("bug"),
    z.literal("bul"),
    z.literal("bur"),
    z.literal("mya"),
    z.literal("byn"),
    z.literal("cad"),
    z.literal("cai"),
    z.literal("car"),
    z.literal("cat"),
    z.literal("cau"),
    z.literal("ceb"),
    z.literal("cel"),
    z.literal("cze"),
    z.literal("ces"),
    z.literal("cha"),
    z.literal("chb"),
    z.literal("che"),
    z.literal("chg"),
    z.literal("chi"),
    z.literal("zho"),
    z.literal("chk"),
    z.literal("chm"),
    z.literal("chn"),
    z.literal("cho"),
    z.literal("chp"),
    z.literal("chr"),
    z.literal("chu"),
    z.literal("chv"),
    z.literal("chy"),
    z.literal("cmc"),
    z.literal("cnr"),
    z.literal("cop"),
    z.literal("cor"),
    z.literal("cos"),
    z.literal("cpe"),
    z.literal("cpf"),
    z.literal("cpp"),
    z.literal("cre"),
    z.literal("crh"),
    z.literal("crp"),
    z.literal("csb"),
    z.literal("cus"),
    z.literal("wel"),
    z.literal("cym"),
    z.literal("dak"),
    z.literal("dan"),
    z.literal("dar"),
    z.literal("day"),
    z.literal("del"),
    z.literal("den"),
    z.literal("ger"),
    z.literal("deu"),
    z.literal("dgr"),
    z.literal("din"),
    z.literal("div"),
    z.literal("doi"),
    z.literal("dra"),
    z.literal("dsb"),
    z.literal("dua"),
    z.literal("dum"),
    z.literal("dut"),
    z.literal("nld"),
    z.literal("dyu"),
    z.literal("dzo"),
    z.literal("efi"),
    z.literal("egy"),
    z.literal("eka"),
    z.literal("gre"),
    z.literal("ell"),
    z.literal("elx"),
    z.literal("eng"),
    z.literal("enm"),
    z.literal("epo"),
    z.literal("est"),
    z.literal("ewe"),
    z.literal("ewo"),
    z.literal("fan"),
    z.literal("fao"),
    z.literal("per"),
    z.literal("fas"),
    z.literal("fat"),
    z.literal("fij"),
    z.literal("fil"),
    z.literal("fin"),
    z.literal("fiu"),
    z.literal("fon"),
    z.literal("fre"),
    z.literal("fra"),
    z.literal("frm"),
    z.literal("fro"),
    z.literal("frr"),
    z.literal("frs"),
    z.literal("fry"),
    z.literal("ful"),
    z.literal("fur"),
    z.literal("gaa"),
    z.literal("gay"),
    z.literal("gba"),
    z.literal("gem"),
    z.literal("geo"),
    z.literal("kat"),
    z.literal("gez"),
    z.literal("gil"),
    z.literal("gla"),
    z.literal("gle"),
    z.literal("glg"),
    z.literal("glv"),
    z.literal("gmh"),
    z.literal("goh"),
    z.literal("gon"),
    z.literal("gor"),
    z.literal("got"),
    z.literal("grb"),
    z.literal("grc"),
    z.literal("grn"),
    z.literal("gsw"),
    z.literal("guj"),
    z.literal("gwi"),
    z.literal("hai"),
    z.literal("hat"),
    z.literal("hau"),
    z.literal("haw"),
    z.literal("heb"),
    z.literal("her"),
    z.literal("hil"),
    z.literal("him"),
    z.literal("hin"),
    z.literal("hit"),
    z.literal("hmn"),
    z.literal("hmo"),
    z.literal("hrv"),
    z.literal("hsb"),
    z.literal("hun"),
    z.literal("hup"),
    z.literal("iba"),
    z.literal("ibo"),
    z.literal("ice"),
    z.literal("isl"),
    z.literal("ido"),
    z.literal("iii"),
    z.literal("ijo"),
    z.literal("iku"),
    z.literal("ile"),
    z.literal("ilo"),
    z.literal("ina"),
    z.literal("inc"),
    z.literal("ind"),
    z.literal("ine"),
    z.literal("inh"),
    z.literal("ipk"),
    z.literal("ira"),
    z.literal("iro"),
    z.literal("ita"),
    z.literal("jav"),
    z.literal("jbo"),
    z.literal("jpn"),
    z.literal("jpr"),
    z.literal("jrb"),
    z.literal("kaa"),
    z.literal("kab"),
    z.literal("kac"),
    z.literal("kal"),
    z.literal("kam"),
    z.literal("kan"),
    z.literal("kar"),
    z.literal("kas"),
    z.literal("kau"),
    z.literal("kaw"),
    z.literal("kaz"),
    z.literal("kbd"),
    z.literal("kha"),
    z.literal("khi"),
    z.literal("khm"),
    z.literal("kho"),
    z.literal("kik"),
    z.literal("kin"),
    z.literal("kir"),
    z.literal("kmb"),
    z.literal("kok"),
    z.literal("kom"),
    z.literal("kon"),
    z.literal("kor"),
    z.literal("kos"),
    z.literal("kpe"),
    z.literal("krc"),
    z.literal("krl"),
    z.literal("kro"),
    z.literal("kru"),
    z.literal("kua"),
    z.literal("kum"),
    z.literal("kur"),
    z.literal("kut"),
    z.literal("lad"),
    z.literal("lah"),
    z.literal("lam"),
    z.literal("lao"),
    z.literal("lat"),
    z.literal("lav"),
    z.literal("lez"),
    z.literal("lim"),
    z.literal("lin"),
    z.literal("lit"),
    z.literal("lol"),
    z.literal("loz"),
    z.literal("ltz"),
    z.literal("lua"),
    z.literal("lub"),
    z.literal("lug"),
    z.literal("lui"),
    z.literal("lun"),
    z.literal("luo"),
    z.literal("lus"),
    z.literal("mac"),
    z.literal("mkd"),
    z.literal("mad"),
    z.literal("mag"),
    z.literal("mah"),
    z.literal("mai"),
    z.literal("mak"),
    z.literal("mal"),
    z.literal("man"),
    z.literal("mao"),
    z.literal("mri"),
    z.literal("map"),
    z.literal("mar"),
    z.literal("mas"),
    z.literal("may"),
    z.literal("msa"),
    z.literal("mdf"),
    z.literal("mdr"),
    z.literal("men"),
    z.literal("mga"),
    z.literal("mic"),
    z.literal("min"),
    z.literal("mis"),
    z.literal("mkh"),
    z.literal("mlg"),
    z.literal("mlt"),
    z.literal("mnc"),
    z.literal("mni"),
    z.literal("mno"),
    z.literal("moh"),
    z.literal("mon"),
    z.literal("mos"),
    z.literal("mul"),
    z.literal("mun"),
    z.literal("mus"),
    z.literal("mwl"),
    z.literal("mwr"),
    z.literal("myn"),
    z.literal("myv"),
    z.literal("nah"),
    z.literal("nai"),
    z.literal("nap"),
    z.literal("nau"),
    z.literal("nav"),
    z.literal("nbl"),
    z.literal("nde"),
    z.literal("ndo"),
    z.literal("nds"),
    z.literal("nep"),
    z.literal("new"),
    z.literal("nia"),
    z.literal("nic"),
    z.literal("niu"),
    z.literal("nno"),
    z.literal("nob"),
    z.literal("nog"),
    z.literal("non"),
    z.literal("nor"),
    z.literal("nqo"),
    z.literal("nso"),
    z.literal("nub"),
    z.literal("nwc"),
    z.literal("nya"),
    z.literal("nym"),
    z.literal("nyn"),
    z.literal("nyo"),
    z.literal("nzi"),
    z.literal("oci"),
    z.literal("oji"),
    z.literal("ori"),
    z.literal("orm"),
    z.literal("osa"),
    z.literal("oss"),
    z.literal("ota"),
    z.literal("oto"),
    z.literal("paa"),
    z.literal("pag"),
    z.literal("pal"),
    z.literal("pam"),
    z.literal("pan"),
    z.literal("pap"),
    z.literal("pau"),
    z.literal("peo"),
    z.literal("phi"),
    z.literal("phn"),
    z.literal("pli"),
    z.literal("pol"),
    z.literal("pon"),
    z.literal("por"),
    z.literal("pra"),
    z.literal("pro"),
    z.literal("pus"),
    z.literal("qaa-qtz"),
    z.literal("que"),
    z.literal("raj"),
    z.literal("rap"),
    z.literal("rar"),
    z.literal("roa"),
    z.literal("roh"),
    z.literal("rom"),
    z.literal("rum"),
    z.literal("ron"),
    z.literal("run"),
    z.literal("rup"),
    z.literal("rus"),
    z.literal("sad"),
    z.literal("sag"),
    z.literal("sah"),
    z.literal("sai"),
    z.literal("sal"),
    z.literal("sam"),
    z.literal("san"),
    z.literal("sas"),
    z.literal("sat"),
    z.literal("scn"),
    z.literal("sco"),
    z.literal("sel"),
    z.literal("sem"),
    z.literal("sga"),
    z.literal("sgn"),
    z.literal("shn"),
    z.literal("sid"),
    z.literal("sin"),
    z.literal("sio"),
    z.literal("sit"),
    z.literal("sla"),
    z.literal("slo"),
    z.literal("slk"),
    z.literal("slv"),
    z.literal("sma"),
    z.literal("sme"),
    z.literal("smi"),
    z.literal("smj"),
    z.literal("smn"),
    z.literal("smo"),
    z.literal("sms"),
    z.literal("sna"),
    z.literal("snd"),
    z.literal("snk"),
    z.literal("sog"),
    z.literal("som"),
    z.literal("son"),
    z.literal("sot"),
    z.literal("spa"),
    z.literal("srd"),
    z.literal("srn"),
    z.literal("srp"),
    z.literal("srr"),
    z.literal("ssa"),
    z.literal("ssw"),
    z.literal("suk"),
    z.literal("sun"),
    z.literal("sus"),
    z.literal("sux"),
    z.literal("swa"),
    z.literal("swe"),
    z.literal("syc"),
    z.literal("syr"),
    z.literal("tah"),
    z.literal("tai"),
    z.literal("tam"),
    z.literal("tat"),
    z.literal("tel"),
    z.literal("tem"),
    z.literal("ter"),
    z.literal("tet"),
    z.literal("tgk"),
    z.literal("tgl"),
    z.literal("tha"),
    z.literal("tig"),
    z.literal("tir"),
    z.literal("tiv"),
    z.literal("tkl"),
    z.literal("tlh"),
    z.literal("tli"),
    z.literal("tmh"),
    z.literal("tog"),
    z.literal("ton"),
    z.literal("tpi"),
    z.literal("tsi"),
    z.literal("tsn"),
    z.literal("tso"),
    z.literal("tuk"),
    z.literal("tum"),
    z.literal("tup"),
    z.literal("tur"),
    z.literal("tut"),
    z.literal("tvl"),
    z.literal("twi"),
    z.literal("tyv"),
    z.literal("udm"),
    z.literal("uga"),
    z.literal("uig"),
    z.literal("ukr"),
    z.literal("umb"),
    z.literal("und"),
    z.literal("urd"),
    z.literal("uzb"),
    z.literal("vai"),
    z.literal("ven"),
    z.literal("vie"),
    z.literal("vol"),
    z.literal("vot"),
    z.literal("wak"),
    z.literal("wal"),
    z.literal("war"),
    z.literal("was"),
    z.literal("wen"),
    z.literal("wln"),
    z.literal("wol"),
    z.literal("xal"),
    z.literal("xho"),
    z.literal("yao"),
    z.literal("yap"),
    z.literal("yid"),
    z.literal("yor"),
    z.literal("ypk"),
    z.literal("zap"),
    z.literal("zbl"),
    z.literal("zen"),
    z.literal("zgh"),
    z.literal("zha"),
    z.literal("znd"),
    z.literal("zul"),
    z.literal("zun"),
    z.literal("zxx"),
    z.literal("zza"),
    z.literal("The language of the book title"),
    z.string(),
  ]),
  languageOriginal: z.union([
    z.literal("aar"),
    z.literal("abk"),
    z.literal("ace"),
    z.literal("ach"),
    z.literal("ada"),
    z.literal("ady"),
    z.literal("afa"),
    z.literal("afh"),
    z.literal("afr"),
    z.literal("ain"),
    z.literal("aka"),
    z.literal("akk"),
    z.literal("alb"),
    z.literal("sqi"),
    z.literal("ale"),
    z.literal("alg"),
    z.literal("alt"),
    z.literal("amh"),
    z.literal("ang"),
    z.literal("anp"),
    z.literal("apa"),
    z.literal("ara"),
    z.literal("arc"),
    z.literal("arg"),
    z.literal("arm"),
    z.literal("hye"),
    z.literal("arn"),
    z.literal("arp"),
    z.literal("art"),
    z.literal("arw"),
    z.literal("asm"),
    z.literal("ast"),
    z.literal("ath"),
    z.literal("aus"),
    z.literal("ava"),
    z.literal("ave"),
    z.literal("awa"),
    z.literal("aym"),
    z.literal("aze"),
    z.literal("bad"),
    z.literal("bai"),
    z.literal("bak"),
    z.literal("bal"),
    z.literal("bam"),
    z.literal("ban"),
    z.literal("baq"),
    z.literal("eus"),
    z.literal("bas"),
    z.literal("bat"),
    z.literal("bej"),
    z.literal("bel"),
    z.literal("bem"),
    z.literal("ben"),
    z.literal("ber"),
    z.literal("bho"),
    z.literal("bih"),
    z.literal("bik"),
    z.literal("bin"),
    z.literal("bis"),
    z.literal("bla"),
    z.literal("bnt"),
    z.literal("tib"),
    z.literal("bod"),
    z.literal("bos"),
    z.literal("bra"),
    z.literal("bre"),
    z.literal("btk"),
    z.literal("bua"),
    z.literal("bug"),
    z.literal("bul"),
    z.literal("bur"),
    z.literal("mya"),
    z.literal("byn"),
    z.literal("cad"),
    z.literal("cai"),
    z.literal("car"),
    z.literal("cat"),
    z.literal("cau"),
    z.literal("ceb"),
    z.literal("cel"),
    z.literal("cze"),
    z.literal("ces"),
    z.literal("cha"),
    z.literal("chb"),
    z.literal("che"),
    z.literal("chg"),
    z.literal("chi"),
    z.literal("zho"),
    z.literal("chk"),
    z.literal("chm"),
    z.literal("chn"),
    z.literal("cho"),
    z.literal("chp"),
    z.literal("chr"),
    z.literal("chu"),
    z.literal("chv"),
    z.literal("chy"),
    z.literal("cmc"),
    z.literal("cnr"),
    z.literal("cop"),
    z.literal("cor"),
    z.literal("cos"),
    z.literal("cpe"),
    z.literal("cpf"),
    z.literal("cpp"),
    z.literal("cre"),
    z.literal("crh"),
    z.literal("crp"),
    z.literal("csb"),
    z.literal("cus"),
    z.literal("wel"),
    z.literal("cym"),
    z.literal("dak"),
    z.literal("dan"),
    z.literal("dar"),
    z.literal("day"),
    z.literal("del"),
    z.literal("den"),
    z.literal("ger"),
    z.literal("deu"),
    z.literal("dgr"),
    z.literal("din"),
    z.literal("div"),
    z.literal("doi"),
    z.literal("dra"),
    z.literal("dsb"),
    z.literal("dua"),
    z.literal("dum"),
    z.literal("dut"),
    z.literal("nld"),
    z.literal("dyu"),
    z.literal("dzo"),
    z.literal("efi"),
    z.literal("egy"),
    z.literal("eka"),
    z.literal("gre"),
    z.literal("ell"),
    z.literal("elx"),
    z.literal("eng"),
    z.literal("enm"),
    z.literal("epo"),
    z.literal("est"),
    z.literal("ewe"),
    z.literal("ewo"),
    z.literal("fan"),
    z.literal("fao"),
    z.literal("per"),
    z.literal("fas"),
    z.literal("fat"),
    z.literal("fij"),
    z.literal("fil"),
    z.literal("fin"),
    z.literal("fiu"),
    z.literal("fon"),
    z.literal("fre"),
    z.literal("fra"),
    z.literal("frm"),
    z.literal("fro"),
    z.literal("frr"),
    z.literal("frs"),
    z.literal("fry"),
    z.literal("ful"),
    z.literal("fur"),
    z.literal("gaa"),
    z.literal("gay"),
    z.literal("gba"),
    z.literal("gem"),
    z.literal("geo"),
    z.literal("kat"),
    z.literal("gez"),
    z.literal("gil"),
    z.literal("gla"),
    z.literal("gle"),
    z.literal("glg"),
    z.literal("glv"),
    z.literal("gmh"),
    z.literal("goh"),
    z.literal("gon"),
    z.literal("gor"),
    z.literal("got"),
    z.literal("grb"),
    z.literal("grc"),
    z.literal("grn"),
    z.literal("gsw"),
    z.literal("guj"),
    z.literal("gwi"),
    z.literal("hai"),
    z.literal("hat"),
    z.literal("hau"),
    z.literal("haw"),
    z.literal("heb"),
    z.literal("her"),
    z.literal("hil"),
    z.literal("him"),
    z.literal("hin"),
    z.literal("hit"),
    z.literal("hmn"),
    z.literal("hmo"),
    z.literal("hrv"),
    z.literal("hsb"),
    z.literal("hun"),
    z.literal("hup"),
    z.literal("iba"),
    z.literal("ibo"),
    z.literal("ice"),
    z.literal("isl"),
    z.literal("ido"),
    z.literal("iii"),
    z.literal("ijo"),
    z.literal("iku"),
    z.literal("ile"),
    z.literal("ilo"),
    z.literal("ina"),
    z.literal("inc"),
    z.literal("ind"),
    z.literal("ine"),
    z.literal("inh"),
    z.literal("ipk"),
    z.literal("ira"),
    z.literal("iro"),
    z.literal("ita"),
    z.literal("jav"),
    z.literal("jbo"),
    z.literal("jpn"),
    z.literal("jpr"),
    z.literal("jrb"),
    z.literal("kaa"),
    z.literal("kab"),
    z.literal("kac"),
    z.literal("kal"),
    z.literal("kam"),
    z.literal("kan"),
    z.literal("kar"),
    z.literal("kas"),
    z.literal("kau"),
    z.literal("kaw"),
    z.literal("kaz"),
    z.literal("kbd"),
    z.literal("kha"),
    z.literal("khi"),
    z.literal("khm"),
    z.literal("kho"),
    z.literal("kik"),
    z.literal("kin"),
    z.literal("kir"),
    z.literal("kmb"),
    z.literal("kok"),
    z.literal("kom"),
    z.literal("kon"),
    z.literal("kor"),
    z.literal("kos"),
    z.literal("kpe"),
    z.literal("krc"),
    z.literal("krl"),
    z.literal("kro"),
    z.literal("kru"),
    z.literal("kua"),
    z.literal("kum"),
    z.literal("kur"),
    z.literal("kut"),
    z.literal("lad"),
    z.literal("lah"),
    z.literal("lam"),
    z.literal("lao"),
    z.literal("lat"),
    z.literal("lav"),
    z.literal("lez"),
    z.literal("lim"),
    z.literal("lin"),
    z.literal("lit"),
    z.literal("lol"),
    z.literal("loz"),
    z.literal("ltz"),
    z.literal("lua"),
    z.literal("lub"),
    z.literal("lug"),
    z.literal("lui"),
    z.literal("lun"),
    z.literal("luo"),
    z.literal("lus"),
    z.literal("mac"),
    z.literal("mkd"),
    z.literal("mad"),
    z.literal("mag"),
    z.literal("mah"),
    z.literal("mai"),
    z.literal("mak"),
    z.literal("mal"),
    z.literal("man"),
    z.literal("mao"),
    z.literal("mri"),
    z.literal("map"),
    z.literal("mar"),
    z.literal("mas"),
    z.literal("may"),
    z.literal("msa"),
    z.literal("mdf"),
    z.literal("mdr"),
    z.literal("men"),
    z.literal("mga"),
    z.literal("mic"),
    z.literal("min"),
    z.literal("mis"),
    z.literal("mkh"),
    z.literal("mlg"),
    z.literal("mlt"),
    z.literal("mnc"),
    z.literal("mni"),
    z.literal("mno"),
    z.literal("moh"),
    z.literal("mon"),
    z.literal("mos"),
    z.literal("mul"),
    z.literal("mun"),
    z.literal("mus"),
    z.literal("mwl"),
    z.literal("mwr"),
    z.literal("myn"),
    z.literal("myv"),
    z.literal("nah"),
    z.literal("nai"),
    z.literal("nap"),
    z.literal("nau"),
    z.literal("nav"),
    z.literal("nbl"),
    z.literal("nde"),
    z.literal("ndo"),
    z.literal("nds"),
    z.literal("nep"),
    z.literal("new"),
    z.literal("nia"),
    z.literal("nic"),
    z.literal("niu"),
    z.literal("nno"),
    z.literal("nob"),
    z.literal("nog"),
    z.literal("non"),
    z.literal("nor"),
    z.literal("nqo"),
    z.literal("nso"),
    z.literal("nub"),
    z.literal("nwc"),
    z.literal("nya"),
    z.literal("nym"),
    z.literal("nyn"),
    z.literal("nyo"),
    z.literal("nzi"),
    z.literal("oci"),
    z.literal("oji"),
    z.literal("ori"),
    z.literal("orm"),
    z.literal("osa"),
    z.literal("oss"),
    z.literal("ota"),
    z.literal("oto"),
    z.literal("paa"),
    z.literal("pag"),
    z.literal("pal"),
    z.literal("pam"),
    z.literal("pan"),
    z.literal("pap"),
    z.literal("pau"),
    z.literal("peo"),
    z.literal("phi"),
    z.literal("phn"),
    z.literal("pli"),
    z.literal("pol"),
    z.literal("pon"),
    z.literal("por"),
    z.literal("pra"),
    z.literal("pro"),
    z.literal("pus"),
    z.literal("qaa-qtz"),
    z.literal("que"),
    z.literal("raj"),
    z.literal("rap"),
    z.literal("rar"),
    z.literal("roa"),
    z.literal("roh"),
    z.literal("rom"),
    z.literal("rum"),
    z.literal("ron"),
    z.literal("run"),
    z.literal("rup"),
    z.literal("rus"),
    z.literal("sad"),
    z.literal("sag"),
    z.literal("sah"),
    z.literal("sai"),
    z.literal("sal"),
    z.literal("sam"),
    z.literal("san"),
    z.literal("sas"),
    z.literal("sat"),
    z.literal("scn"),
    z.literal("sco"),
    z.literal("sel"),
    z.literal("sem"),
    z.literal("sga"),
    z.literal("sgn"),
    z.literal("shn"),
    z.literal("sid"),
    z.literal("sin"),
    z.literal("sio"),
    z.literal("sit"),
    z.literal("sla"),
    z.literal("slo"),
    z.literal("slk"),
    z.literal("slv"),
    z.literal("sma"),
    z.literal("sme"),
    z.literal("smi"),
    z.literal("smj"),
    z.literal("smn"),
    z.literal("smo"),
    z.literal("sms"),
    z.literal("sna"),
    z.literal("snd"),
    z.literal("snk"),
    z.literal("sog"),
    z.literal("som"),
    z.literal("son"),
    z.literal("sot"),
    z.literal("spa"),
    z.literal("srd"),
    z.literal("srn"),
    z.literal("srp"),
    z.literal("srr"),
    z.literal("ssa"),
    z.literal("ssw"),
    z.literal("suk"),
    z.literal("sun"),
    z.literal("sus"),
    z.literal("sux"),
    z.literal("swa"),
    z.literal("swe"),
    z.literal("syc"),
    z.literal("syr"),
    z.literal("tah"),
    z.literal("tai"),
    z.literal("tam"),
    z.literal("tat"),
    z.literal("tel"),
    z.literal("tem"),
    z.literal("ter"),
    z.literal("tet"),
    z.literal("tgk"),
    z.literal("tgl"),
    z.literal("tha"),
    z.literal("tig"),
    z.literal("tir"),
    z.literal("tiv"),
    z.literal("tkl"),
    z.literal("tlh"),
    z.literal("tli"),
    z.literal("tmh"),
    z.literal("tog"),
    z.literal("ton"),
    z.literal("tpi"),
    z.literal("tsi"),
    z.literal("tsn"),
    z.literal("tso"),
    z.literal("tuk"),
    z.literal("tum"),
    z.literal("tup"),
    z.literal("tur"),
    z.literal("tut"),
    z.literal("tvl"),
    z.literal("twi"),
    z.literal("tyv"),
    z.literal("udm"),
    z.literal("uga"),
    z.literal("uig"),
    z.literal("ukr"),
    z.literal("umb"),
    z.literal("und"),
    z.literal("urd"),
    z.literal("uzb"),
    z.literal("vai"),
    z.literal("ven"),
    z.literal("vie"),
    z.literal("vol"),
    z.literal("vot"),
    z.literal("wak"),
    z.literal("wal"),
    z.literal("war"),
    z.literal("was"),
    z.literal("wen"),
    z.literal("wln"),
    z.literal("wol"),
    z.literal("xal"),
    z.literal("xho"),
    z.literal("yao"),
    z.literal("yap"),
    z.literal("yid"),
    z.literal("yor"),
    z.literal("ypk"),
    z.literal("zap"),
    z.literal("zbl"),
    z.literal("zen"),
    z.literal("zgh"),
    z.literal("zha"),
    z.literal("znd"),
    z.literal("zul"),
    z.literal("zun"),
    z.literal("zxx"),
    z.literal("zza"),
    z.literal("The language of the book title"),
    z.string(),
  ]),
  organisation: firestoreOrganisationShortSchema.nullable(),
  format: z.array(
    z
      .union([
        z.literal("A101"),
        z.literal("A102"),
        z.literal("A103"),
        z.literal("A104"),
        z.literal("A105"),
        z.literal("A106"),
        z.literal("A107"),
        z.literal("A108"),
        z.literal("A109"),
        z.literal("A110"),
        z.literal("A111"),
        z.literal("A112"),
        z.literal("A113"),
        z.literal("A201"),
        z.literal("A202"),
        z.literal("A203"),
        z.literal("A204"),
        z.literal("A205"),
        z.literal("A206"),
        z.literal("A207"),
        z.literal("A208"),
        z.literal("A209"),
        z.literal("A210"),
        z.literal("A211"),
        z.literal("A212"),
        z.literal("A301"),
        z.literal("A302"),
        z.literal("A303"),
        z.literal("A304"),
        z.literal("A305"),
        z.literal("A310"),
        z.literal("A311"),
        z.literal("A312"),
        z.literal("A400"),
        z.literal("A401"),
        z.literal("A402"),
        z.literal("A403"),
        z.literal("A404"),
        z.literal("A410"),
        z.literal("A420"),
        z.literal("A421"),
        z.literal("A441"),
        z.literal("A451"),
        z.literal("A471"),
        z.literal("B101"),
        z.literal("B102"),
        z.literal("B103"),
        z.literal("B104"),
        z.literal("B105"),
        z.literal("B106"),
        z.literal("B107"),
        z.literal("B108"),
        z.literal("B109"),
        z.literal("B110"),
        z.literal("B111"),
        z.literal("B112"),
        z.literal("B113"),
        z.literal("B114"),
        z.literal("B115"),
        z.literal("B116"),
        z.literal("B117"),
        z.literal("B118"),
        z.literal("B119"),
        z.literal("B120"),
        z.literal("B121"),
        z.literal("B122"),
        z.literal("B123"),
        z.literal("B124"),
        z.literal("B125"),
        z.literal("B126"),
        z.literal("B127"),
        z.literal("B128"),
        z.literal("B129"),
        z.literal("B130"),
        z.literal("B131"),
        z.literal("B132"),
        z.literal("B133"),
        z.literal("B134"),
        z.literal("B135"),
        z.literal("B201"),
        z.literal("B202"),
        z.literal("B204"),
        z.literal("B205"),
        z.literal("B206"),
        z.literal("B207"),
        z.literal("B208"),
        z.literal("B209"),
        z.literal("B210"),
        z.literal("B212"),
        z.literal("B213"),
        z.literal("B214"),
        z.literal("B215"),
        z.literal("B216"),
        z.literal("B221"),
        z.literal("B222"),
        z.literal("B223"),
        z.literal("B224"),
        z.literal("B301"),
        z.literal("B302"),
        z.literal("B303"),
        z.literal("B304"),
        z.literal("B305"),
        z.literal("B306"),
        z.literal("B307"),
        z.literal("B308"),
        z.literal("B309"),
        z.literal("B310"),
        z.literal("B311"),
        z.literal("B312"),
        z.literal("B313"),
        z.literal("B314"),
        z.literal("B315"),
        z.literal("B316"),
        z.literal("B317"),
        z.literal("B318"),
        z.literal("B400"),
        z.literal("B401"),
        z.literal("B402"),
        z.literal("B403"),
        z.literal("B404"),
        z.literal("B405"),
        z.literal("B406"),
        z.literal("B409"),
        z.literal("B410"),
        z.literal("B411"),
        z.literal("B412"),
        z.literal("B413"),
        z.literal("B414"),
        z.literal("B415"),
        z.literal("B416"),
        z.literal("B417"),
        z.literal("B420"),
        z.literal("B421"),
        z.literal("B422"),
        z.literal("B423"),
        z.literal("B424"),
        z.literal("B425"),
        z.literal("B426"),
        z.literal("B427"),
        z.literal("B428"),
        z.literal("B501"),
        z.literal("B502"),
        z.literal("B503"),
        z.literal("B504"),
        z.literal("B505"),
        z.literal("B506"),
        z.literal("B507"),
        z.literal("B508"),
        z.literal("B509"),
        z.literal("B510"),
        z.literal("B511"),
        z.literal("B512"),
        z.literal("B513"),
        z.literal("B514"),
        z.literal("B515"),
        z.literal("B516"),
        z.literal("B517"),
        z.literal("B518"),
        z.literal("B519"),
        z.literal("B520"),
        z.literal("B601"),
        z.literal("B602"),
        z.literal("B603"),
        z.literal("B604"),
        z.literal("B605"),
        z.literal("B606"),
        z.literal("B607"),
        z.literal("B608"),
        z.literal("B609"),
        z.literal("B610"),
        z.literal("B611"),
        z.literal("B701"),
        z.literal("B702"),
        z.literal("B703"),
        z.literal("B704"),
        z.literal("B705"),
        z.literal("B706"),
        z.literal("B707"),
        z.literal("B708"),
        z.literal("B709"),
        z.literal("B721"),
        z.literal("B722"),
        z.literal("B732"),
        z.literal("B750"),
        z.literal("B751"),
        z.literal("B752"),
        z.literal("B753"),
        z.literal("C750"),
        z.literal("C751"),
        z.literal("D101"),
        z.literal("D102"),
        z.literal("D103"),
        z.literal("D104"),
        z.literal("D105"),
        z.literal("D201"),
        z.literal("D202"),
        z.literal("D203"),
        z.literal("D204"),
        z.literal("D205"),
        z.literal("D206"),
        z.literal("D207"),
        z.literal("D301"),
        z.literal("D302"),
        z.literal("D303"),
        z.literal("D304"),
        z.literal("D305"),
        z.literal("D306"),
        z.literal("D307"),
        z.literal("D308"),
        z.literal("D309"),
        z.literal("D310"),
        z.literal("D311"),
        z.literal("D312"),
        z.literal("D313"),
        z.literal("D315"),
        z.literal("D316"),
        z.literal("D318"),
        z.literal("D319"),
        z.literal("D320"),
        z.literal("D321"),
        z.literal("D322"),
        z.literal("D323"),
        z.literal("D324"),
        z.literal("E100"),
        z.literal("E101"),
        z.literal("E102"),
        z.literal("E103"),
        z.literal("E104"),
        z.literal("E105"),
        z.literal("E106"),
        z.literal("E107"),
        z.literal("E108"),
        z.literal("E109"),
        z.literal("E110"),
        z.literal("E111"),
        z.literal("E112"),
        z.literal("E113"),
        z.literal("E114"),
        z.literal("E115"),
        z.literal("E116"),
        z.literal("E117"),
        z.literal("E118"),
        z.literal("E119"),
        z.literal("E120"),
        z.literal("E121"),
        z.literal("E122"),
        z.literal("E123"),
        z.literal("E124"),
        z.literal("E125"),
        z.literal("E126"),
        z.literal("E127"),
        z.literal("E128"),
        z.literal("E129"),
        z.literal("E130"),
        z.literal("E131"),
        z.literal("E132"),
        z.literal("E133"),
        z.literal("E134"),
        z.literal("E135"),
        z.literal("E136"),
        z.literal("E139"),
        z.literal("E140"),
        z.literal("E141"),
        z.literal("E142"),
        z.literal("E143"),
        z.literal("E144"),
        z.literal("E145"),
        z.literal("E146"),
        z.literal("E147"),
        z.literal("E148"),
        z.literal("E149"),
        z.literal("E200"),
        z.literal("E201"),
        z.literal("E202"),
        z.literal("E203"),
        z.literal("E204"),
        z.literal("E205"),
        z.literal("E206"),
        z.literal("E210"),
        z.literal("E211"),
        z.literal("E212"),
        z.literal("E213"),
        z.literal("E221"),
        z.literal("E222"),
        z.literal("E223"),
        z.literal("E224"),
        z.literal("E225"),
        z.literal("E226"),
        z.literal("E227"),
        z.literal("L101"),
        z.literal("P091"),
        z.literal("P092"),
        z.literal("P096"),
        z.literal("P097"),
        z.literal("P098"),
        z.literal("P099"),
        z.literal("P101"),
        z.literal("P102"),
        z.literal("P103"),
        z.literal("P104"),
        z.literal("P105"),
        z.literal("P106"),
        z.literal("P107"),
        z.literal("P108"),
        z.literal("P109"),
        z.literal("P110"),
        z.literal("P111"),
        z.literal("P112"),
        z.literal("P113"),
        z.literal("P114"),
        z.literal("P115"),
        z.literal("P116"),
        z.literal("P131"),
        z.literal("P132"),
        z.literal("P133"),
        z.literal("P120"),
        z.literal("P121"),
        z.literal("P122"),
        z.literal("P123"),
        z.literal("P124"),
        z.literal("P125"),
        z.literal("P126"),
        z.literal("P127"),
        z.literal("P201"),
        z.literal("P202"),
        z.literal("P203"),
        z.literal("P204"),
        z.literal("P301"),
        z.literal("P305"),
        z.literal("P306"),
        z.literal("V201"),
        z.literal("V202"),
        z.literal("V203"),
        z.literal("V205"),
        z.literal("V206"),
        z.literal("V207"),
        z.literal("V220"),
        z.literal("V221"),
        z.literal("Z101"),
        z.literal("Z102"),
        z.literal("Z103"),
        z.literal("Z111"),
        z.literal("Z112"),
        z.literal("Z113"),
        z.literal("Z121"),
      ])
      .nullable(),
  ),
  formatDescription: z.array(
    z
      .union([
        z.literal("CD standard audio format"),
        z.literal("SACD super audio format"),
        z.literal("MP3 format"),
        z.literal("WAV format"),
        z.literal("Real Audio format"),
        z.literal("WMA"),
        z.literal("AAC"),
        z.literal("Ogg/Vorbis"),
        z.literal("Audible"),
        z.literal("FLAC"),
        z.literal("AIFF"),
        z.literal("ALAC"),
        z.literal("W3C Audiobook format"),
        z.literal("DAISY 2: full audio with title only (no navigation)"),
        z.literal("DAISY 2: full audio with navigation (no text)"),
        z.literal("DAISY 2: full audio with navigation and partial text"),
        z.literal("DAISY 2: full audio with navigation and full text"),
        z.literal("DAISY 2: full text with navigation and partial audio"),
        z.literal("DAISY 2: full text with navigation and no audio"),
        z.literal("DAISY 3: full audio with title only (no navigation)"),
        z.literal("DAISY 3: full audio with navigation (no text)"),
        z.literal("DAISY 3: full audio with navigation and partial text"),
        z.literal("DAISY 3: full audio with navigation and full text"),
        z.literal("DAISY 3: full text with navigation and partial audio"),
        z.literal("DAISY 3: full text with navigation and no audio"),
        z.literal("Standalone audio"),
        z.literal("Readalong audio"),
        z.literal("Playalong audio"),
        z.literal("Speakalong audio"),
        z.literal("Synchronised audio"),
        z.literal("Sound effects"),
        z.literal("Background music"),
        z.literal("Without background sounds"),
        z.literal("64kbits/s"),
        z.literal("128kbits/s"),
        z.literal("192kbits/s"),
        z.literal("256kbits/s"),
        z.literal("320kbits/s"),
        z.literal("Mono"),
        z.literal("Stereo"),
        z.literal("Stereo 2.1"),
        z.literal("Surround 4.1"),
        z.literal("Surround 5.1"),
        z.literal("Dolby Atmos"),
        z.literal("Mass market (rack) paperback"),
        z.literal("Trade paperback (US)"),
        z.literal("Digest format paperback"),
        z.literal("A-format paperback"),
        z.literal("B-format paperback"),
        z.literal("Trade paperback (UK)"),
        z.literal("Tall rack paperback (US)"),
        z.literal("A5 size Tankobon"),
        z.literal("JIS B5 size Tankobon"),
        z.literal("JIS B6 size Tankobon"),
        z.literal("A6 size Bunko"),
        z.literal("B40-dori Shinsho"),
        z.literal("Pocket (Sweden, Norway, France)"),
        z.literal("Storpocket (Sweden)"),
        z.literal("Kartonnage (Sweden)"),
        z.literal("Flexband (Sweden)"),
        z.literal("Mook / Bookazine"),
        z.literal("Dwarsligger"),
        z.literal("46 size"),
        z.literal("46-Henkei size"),
        z.literal("A4"),
        z.literal("A4-Henkei size"),
        z.literal("A5-Henkei size"),
        z.literal("B5-Henkei size"),
        z.literal("B6-Henkei size"),
        z.literal("AB size"),
        z.literal("JIS B7 size"),
        z.literal("Kiku size"),
        z.literal("Kiku-Henkei size"),
        z.literal("JIS B4 size"),
        z.literal("Paperback (DE)"),
        z.literal("Libro de bolsillo"),
        z.literal("Pocket sized"),
        z.literal("A5"),
        z.literal("Mass market max paperback"),
        z.literal("Coloring / join-the-dot book"),
        z.literal("Lift-the-flap book"),
        z.literal("Miniature book"),
        z.literal("Moving picture / flicker book"),
        z.literal("Pop-up book"),
        z.literal("Scented / \u2018smelly\u2019 book"),
        z.literal("Sound story / \u2018noisy\u2019 book"),
        z.literal("Sticker book"),
        z.literal("Touch-and-feel book"),
        z.literal("Die-cut book"),
        z.literal("Book-as-toy"),
        z.literal("Soft-to-touch book"),
        z.literal("Fuzzy-felt book"),
        z.literal("Press-out puzzle pieces"),
        z.literal("Picture book"),
        z.literal("\u2018Carousel\u2019 book"),
        z.literal("Pull-the-tab book"),
        z.literal("\u2018Wordless\u2019 book"),
        z.literal(
          "Loose leaf or partwork \u2013 sheets / parts and binder / wallet",
        ),
        z.literal("Loose leaf or partwork \u2013 binder / wallet only"),
        z.literal("Loose leaf or partwork \u2013 sheets / parts only"),
        z.literal("Sewn"),
        z.literal("Unsewn / adhesive bound"),
        z.literal("Library binding"),
        z.literal("Reinforced binding"),
        z.literal("Half bound"),
        z.literal("Quarter bound"),
        z.literal("Saddle-sewn"),
        z.literal("Comb bound"),
        z.literal("Wire-O"),
        z.literal("Concealed wire"),
        z.literal("Coiled wire bound"),
        z.literal("Trade binding"),
        z.literal("Swiss binding"),
        z.literal("Notched binding"),
        z.literal("Lay-flat binding"),
        z.literal("Self-covered"),
        z.literal("Cloth over boards"),
        z.literal("Paper over boards"),
        z.literal("Leather, real"),
        z.literal("Leather, imitation"),
        z.literal("Leather, bonded"),
        z.literal("Vellum"),
        z.literal("Cloth"),
        z.literal("Imitation cloth"),
        z.literal("Velvet"),
        z.literal("Flexible plastic/vinyl cover"),
        z.literal("Plastic-covered"),
        z.literal("Vinyl-covered"),
        z.literal("Laminated cover"),
        z.literal("Card cover"),
        z.literal("Duplex-printed cover"),
        z.literal("Delicate cover / jacket finish"),
        z.literal("With elasticated strap"),
        z.literal("French flaps"),
        z.literal("With flaps"),
        z.literal("With dust jacket"),
        z.literal("Rough front"),
        z.literal("Deckle edge"),
        z.literal("Torn edge"),
        z.literal("Hand-sewn"),
        z.literal("With printed dust jacket"),
        z.literal("With translucent dust cover"),
        z.literal("With thumb index"),
        z.literal("With ribbon marker(s)"),
        z.literal("With zip fastener"),
        z.literal("With button snap fastener"),
        z.literal("With leather edge lining"),
        z.literal("Foldout"),
        z.literal("Wide margin"),
        z.literal("With fastening strap"),
        z.literal("With perforated pages"),
        z.literal("With serialized authenticity token"),
        z.literal("With printed marginalia"),
        z.literal("Die-cut pages"),
        z.literal("Leather edge"),
        z.literal("Turn-around book"),
        z.literal("Unflipped manga format"),
        z.literal("Back-to-back book"),
        z.literal("Flipped manga format"),
        z.literal("Variant turn-around book"),
        z.literal("Page progression LTR"),
        z.literal("Page progression RTL"),
        z.literal("Page progression TTB"),
        z.literal("Page progression other"),
        z.literal("Syllabification"),
        z.literal("Upper case only"),
        z.literal("UK Uncontracted Braille"),
        z.literal("UK Contracted Braille"),
        z.literal("US Braille"),
        z.literal("US Uncontracted Braille"),
        z.literal("US Contracted Braille"),
        z.literal("Unified English Braille"),
        z.literal("Moon"),
        z.literal("Unified English Uncontracted Braille"),
        z.literal("Unified English Contracted Braille"),
        z.literal("Simplified Chinese Mainland Braille"),
        z.literal("Taiwan Braille"),
        z.literal("UEB Contracted Braille"),
        z.literal("Tactile images"),
        z.literal("Lenticular images"),
        z.literal("Anaglyph images"),
        z.literal("Holographic images"),
        z.literal("Raised 3D relief"),
        z.literal("Other imaging system (print)"),
        z.literal("Real Video format"),
        z.literal("Quicktime format"),
        z.literal("AVI format"),
        z.literal("Windows Media Video format"),
        z.literal("MPEG-4"),
        z.literal("MS-DOS"),
        z.literal("Windows"),
        z.literal("Macintosh"),
        z.literal("UNIX / LINUX"),
        z.literal("Other operating system(s)"),
        z.literal("Palm OS"),
        z.literal("Windows Mobile"),
        z.literal("Microsoft XBox"),
        z.literal("Nintendo Gameboy Color"),
        z.literal("Nintendo Gameboy Advanced"),
        z.literal("Nintendo Gameboy"),
        z.literal("Nintendo Gamecube"),
        z.literal("Nintendo 64"),
        z.literal("Sega Dreamcast"),
        z.literal("Sega Genesis/Megadrive"),
        z.literal("Sega Saturn"),
        z.literal("Sony PlayStation 1"),
        z.literal("Sony PlayStation 2"),
        z.literal("Nintendo Dual Screen"),
        z.literal("Sony PlayStation 3"),
        z.literal("Nintendo Wii"),
        z.literal("Sony PlayStation Portable (PSP)"),
        z.literal("Sony PlayStation 4"),
        z.literal("Sony PlayStation Vita"),
        z.literal("Microsoft Xbox One"),
        z.literal("Nintendo Switch"),
        z.literal("Nintendo Wii U"),
        z.literal("Sony PlayStation 5"),
        z.literal("Microsoft Xbox Series X / S"),
        z.literal("Other"),
        z.literal("EPUB"),
        z.literal("OEB"),
        z.literal("DOC"),
        z.literal("DOCX"),
        z.literal("HTML"),
        z.literal("ODF"),
        z.literal("PDF"),
        z.literal("PDF/A"),
        z.literal("RTF"),
        z.literal("SGML"),
        z.literal("TCR"),
        z.literal("TXT"),
        z.literal("XHTML"),
        z.literal("zTXT"),
        z.literal("XPS"),
        z.literal("Amazon Kindle"),
        z.literal("BBeB"),
        z.literal("DXReader"),
        z.literal("EBL"),
        z.literal("Ebrary"),
        z.literal("eReader"),
        z.literal("Exebook"),
        z.literal("Franklin eBookman"),
        z.literal("Gemstar Rocketbook"),
        z.literal("iSilo"),
        z.literal("Microsoft Reader"),
        z.literal("Mobipocket"),
        z.literal("MyiLibrary"),
        z.literal("NetLibrary"),
        z.literal("Plucker"),
        z.literal("VitalBook"),
        z.literal("Vook"),
        z.literal("Google Edition"),
        z.literal("Book \u2018app\u2019 for iOS"),
        z.literal("Book \u2018app\u2019 for Android"),
        z.literal("Book \u2018app\u2019 for other operating system"),
        z.literal("CEB"),
        z.literal("CEBX"),
        z.literal("iBook"),
        z.literal("ePIB"),
        z.literal("SCORM"),
        z.literal("EBP"),
        z.literal("Page Perfect"),
        z.literal("BRF"),
        z.literal("Erudit"),
        z.literal("Amazon Kindle Print Replica"),
        z.literal("Comic Book Archive"),
        z.literal("Reflowable"),
        z.literal("Fixed format"),
        z.literal("Readable offline"),
        z.literal("Requires network connection"),
        z.literal("Content removed"),
        z.literal("Visible page numbering"),
        z.literal("No preferred page progression"),
        z.literal("Landscape"),
        z.literal("Portrait"),
        z.literal("Square"),
        z.literal("Vertical scrolling"),
        z.literal("5:4"),
        z.literal("4:3"),
        z.literal("3:2"),
        z.literal("16:10"),
        z.literal("16:9"),
        z.literal("18:9"),
        z.literal("21:9"),
        z.literal("Laminated"),
        z.literal("Calendar with write-in space"),
        z.literal("Calendar without write-in space"),
        z.literal("Multiple months per page"),
        z.literal("One month per page"),
        z.literal("One week per page"),
        z.literal("One day per page"),
        z.literal("Desk calendar or diary"),
        z.literal("Mini calendar or pocket diary"),
        z.literal("Engagement calendar or Appointment diary"),
        z.literal("Day by day calendar"),
        z.literal("Poster calendar"),
        z.literal("Wall calendar"),
        z.literal("Perpetual calendar or diary"),
        z.literal("Advent calendar"),
        z.literal("Bookmark calendar"),
        z.literal("Student or Academic calendar or diary"),
        z.literal("Project calendar"),
        z.literal("Almanac calendar"),
        z.literal("Other calendar, diary or organiser"),
        z.literal("Other calendar or organiser product"),
        z.literal("Family planner"),
        z.literal("Postcard calendar"),
        z.literal("Blank calendar"),
        z.literal("Panoramic calendar"),
        z.literal("Columnar calendar"),
        z.literal("Picture story cards"),
        z.literal("Flash cards"),
        z.literal("Reference cards"),
        z.literal("Recreation cards"),
        z.literal("Postcards"),
        z.literal("Greeting cards"),
        z.literal("Gift cards"),
        z.literal("Certificate cards"),
        z.literal("Hardback (stationery)"),
        z.literal("Paperback / softback (stationery)"),
        z.literal("Spiral bound (stationery)"),
        z.literal("Leather / fine binding (stationery)"),
        z.literal("With hanging strips"),
        z.literal("Single-sided"),
        z.literal("Double-sided"),
        z.literal("PAL"),
        z.literal("NTSC"),
        z.literal("SECAM"),
        z.literal("HD"),
        z.literal("UHD"),
        z.literal("3D video"),
        z.literal("Home use"),
        z.literal("Classroom use"),
        z.literal("Wooden"),
        z.literal("Plastic"),
        z.literal("Board"),
        z.literal("3D puzzle"),
        z.literal("Noisy kit / puzzle / toy"),
        z.literal("Puppet"),
        z.literal("Extra large pieces"),
      ])
      .nullable(),
  ),
  productGroup: z.union([
    z.literal("00"),
    z.literal("AA"),
    z.literal("AB"),
    z.literal("AC"),
    z.literal("AD"),
    z.literal("AE"),
    z.literal("AF"),
    z.literal("AG"),
    z.literal("AH"),
    z.literal("AI"),
    z.literal("AJ"),
    z.literal("AK"),
    z.literal("AL"),
    z.literal("AM"),
    z.literal("AN"),
    z.literal("AO"),
    z.literal("AZ"),
    z.literal("BA"),
    z.literal("BB"),
    z.literal("BC"),
    z.literal("BD"),
    z.literal("BE"),
    z.literal("BF"),
    z.literal("BG"),
    z.literal("BH"),
    z.literal("BI"),
    z.literal("BJ"),
    z.literal("BK"),
    z.literal("BL"),
    z.literal("BM"),
    z.literal("BN"),
    z.literal("BO"),
    z.literal("BP"),
    z.literal("BZ"),
    z.literal("CA"),
    z.literal("CB"),
    z.literal("CC"),
    z.literal("CD"),
    z.literal("CE"),
    z.literal("CZ"),
    z.literal("DA"),
    z.literal("DB"),
    z.literal("DC"),
    z.literal("DE"),
    z.literal("DF"),
    z.literal("DG"),
    z.literal("DH"),
    z.literal("DI"),
    z.literal("DJ"),
    z.literal("DK"),
    z.literal("DL"),
    z.literal("DM"),
    z.literal("DN"),
    z.literal("DO"),
    z.literal("DZ"),
    z.literal("EA"),
    z.literal("EB"),
    z.literal("EC"),
    z.literal("ED"),
    z.literal("FA"),
    z.literal("FC"),
    z.literal("FD"),
    z.literal("FE"),
    z.literal("FF"),
    z.literal("FZ"),
    z.literal("LA"),
    z.literal("LB"),
    z.literal("LC"),
    z.literal("MA"),
    z.literal("MB"),
    z.literal("MC"),
    z.literal("MZ"),
    z.literal("PA"),
    z.literal("PB"),
    z.literal("PC"),
    z.literal("PD"),
    z.literal("PE"),
    z.literal("PF"),
    z.literal("PG"),
    z.literal("PH"),
    z.literal("PI"),
    z.literal("PJ"),
    z.literal("PK"),
    z.literal("PL"),
    z.literal("PM"),
    z.literal("PN"),
    z.literal("PO"),
    z.literal("PP"),
    z.literal("PQ"),
    z.literal("PR"),
    z.literal("PS"),
    z.literal("PT"),
    z.literal("PU"),
    z.literal("PV"),
    z.literal("PZ"),
    z.literal("SA"),
    z.literal("SB"),
    z.literal("SC"),
    z.literal("SD"),
    z.literal("SE"),
    z.literal("SF"),
    z.literal("SG"),
    z.literal("VA"),
    z.literal("VF"),
    z.literal("VI"),
    z.literal("VJ"),
    z.literal("VK"),
    z.literal("VL"),
    z.literal("VM"),
    z.literal("VN"),
    z.literal("VO"),
    z.literal("VP"),
    z.literal("VQ"),
    z.literal("VZ"),
    z.literal("XA"),
    z.literal("XB"),
    z.literal("XC"),
    z.literal("XD"),
    z.literal("XE"),
    z.literal("XF"),
    z.literal("XG"),
    z.literal("XH"),
    z.literal("XI"),
    z.literal("XJ"),
    z.literal("XK"),
    z.literal("XL"),
    z.literal("XM"),
    z.literal("XN"),
    z.literal("XO"),
    z.literal("XY"),
    z.literal("XZ"),
    z.literal("ZA"),
    z.literal("ZB"),
    z.literal("ZC"),
    z.literal("ZD"),
    z.literal("ZE"),
    z.literal("ZF"),
    z.literal("ZG"),
    z.literal("ZH"),
    z.literal("ZI"),
    z.literal("ZJ"),
    z.literal("ZK"),
    z.literal("ZL"),
    z.literal("ZM"),
    z.literal("ZN"),
    z.literal("ZO"),
    z.literal("ZP"),
    z.literal("ZX"),
    z.literal("ZY"),
    z.literal("ZZ"),
  ]),
  productGroupDescription: z.union([
    z.literal("Undefined"),
    z.literal("Audio"),
    z.literal("Audio cassette"),
    z.literal("CD-Audio"),
    z.literal("DAT"),
    z.literal("Audio disc"),
    z.literal("Audio tape"),
    z.literal("MiniDisc"),
    z.literal("CD-Extra"),
    z.literal("DVD Audio"),
    z.literal("Downloadable audio file"),
    z.literal("Pre-recorded digital audio player"),
    z.literal("Pre-recorded SD card"),
    z.literal("LP"),
    z.literal("Downloadable and online audio file"),
    z.literal("Online audio file"),
    z.literal("Other audio format"),
    z.literal("Book"),
    z.literal("Hardback"),
    z.literal("Paperback / softback"),
    z.literal("Loose-leaf"),
    z.literal("Spiral bound"),
    z.literal("Pamphlet"),
    z.literal("Leather / fine binding"),
    z.literal("Board book"),
    z.literal("Rag book"),
    z.literal("Bath book"),
    z.literal("Novelty book"),
    z.literal("Slide bound"),
    z.literal("Big book"),
    z.literal("Part-work (fasc\u00EDculo)"),
    z.literal("Fold-out book or chart"),
    z.literal("Foam book"),
    z.literal("Other book format"),
    z.literal("Sheet map"),
    z.literal("Sheet map, folded"),
    z.literal("Sheet map, flat"),
    z.literal("Sheet map, rolled"),
    z.literal("Globe"),
    z.literal("Other cartographic"),
    z.literal("Digital (on physical carrier)"),
    z.literal("CD-ROM"),
    z.literal("CD-I"),
    z.literal("Game cartridge"),
    z.literal("Diskette"),
    z.literal("Electronic book text"),
    z.literal("Online resource"),
    z.literal("DVD-ROM"),
    z.literal("Secure Digital (SD) Memory Card"),
    z.literal("Compact Flash Memory Card"),
    z.literal("Memory Stick Memory Card"),
    z.literal("USB Flash Drive"),
    z.literal("Double-sided CD/DVD"),
    z.literal("BR-ROM"),
    z.literal("Other digital carrier"),
    z.literal("Digital (delivered electronically)"),
    z.literal("Digital download and online"),
    z.literal("Digital online"),
    z.literal("Digital download"),
    z.literal("Film or transparency"),
    z.literal("Slides"),
    z.literal("OHP transparencies"),
    z.literal("Filmstrip"),
    z.literal("Film"),
    z.literal("Other film or transparency format"),
    z.literal("Digital product license"),
    z.literal("Digital product license key"),
    z.literal("Digital product license code"),
    z.literal("Microform"),
    z.literal("Microfiche"),
    z.literal("Microfilm"),
    z.literal("Other microform"),
    z.literal("Miscellaneous print"),
    z.literal("Address book"),
    z.literal("Calendar"),
    z.literal("Cards"),
    z.literal("Copymasters"),
    z.literal("Diary or journal"),
    z.literal("Frieze"),
    z.literal("Kit"),
    z.literal("Sheet music"),
    z.literal("Postcard book or pack"),
    z.literal("Poster"),
    z.literal("Record book"),
    z.literal("Wallet or folder"),
    z.literal("Pictures or photographs"),
    z.literal("Wallchart"),
    z.literal("Stickers"),
    z.literal("Plate (l\u00E1mina)"),
    z.literal("Notebook / blank book"),
    z.literal("Organizer"),
    z.literal("Bookmark"),
    z.literal("Leaflet"),
    z.literal("Book plates"),
    z.literal("Other printed item"),
    z.literal("Multiple-component retail product"),
    z.literal("Multiple-component retail product, boxed"),
    z.literal("Multiple-component retail product, slip-cased"),
    z.literal("Multiple-component retail product, shrink-wrapped"),
    z.literal("Multiple-component retail product, loose"),
    z.literal("Multiple-component retail product, part(s) enclosed"),
    z.literal("Multiple-component retail product, entirely digital"),
    z.literal("Video"),
    z.literal("Videodisc"),
    z.literal("DVD video"),
    z.literal("VHS video"),
    z.literal("Betamax video"),
    z.literal("VCD"),
    z.literal("SVCD"),
    z.literal("HD DVD"),
    z.literal("Blu-ray"),
    z.literal("UMD Video"),
    z.literal("CBHD"),
    z.literal("Other video format"),
    z.literal("Trade-only material"),
    z.literal("Dumpbin \u2013 empty"),
    z.literal("Dumpbin \u2013 filled"),
    z.literal("Counterpack \u2013 empty"),
    z.literal("Counterpack \u2013 filled"),
    z.literal("Poster, promotional"),
    z.literal("Shelf strip"),
    z.literal("Window piece"),
    z.literal("Streamer"),
    z.literal("Spinner \u2013 empty"),
    z.literal("Large book display"),
    z.literal("Shrink-wrapped pack"),
    z.literal("Boxed pack"),
    z.literal("Pack (outer packaging unspecified)"),
    z.literal("Spinner \u2013 filled"),
    z.literal("Other point of sale \u2013 including retail product"),
    z.literal("Other point of sale"),
    z.literal("General merchandise"),
    z.literal("Doll or figure"),
    z.literal("Soft toy"),
    z.literal("Toy"),
    z.literal("Game"),
    z.literal("T-shirt"),
    z.literal("E-book reader"),
    z.literal("Tablet computer"),
    z.literal("Audiobook player"),
    z.literal("Jigsaw"),
    z.literal("Mug"),
    z.literal("Tote bag"),
    z.literal("Tableware"),
    z.literal("Umbrella"),
    z.literal("Paints, crayons, pencils"),
    z.literal("Handicraft kit"),
    z.literal("Other toy/game accessories"),
    z.literal("Other apparel"),
    z.literal("Other merchandise"),
  ]),
  relatedProducts: z.array(firestoreProductRelatedProductSchema).optional(),
  publisher: z.string(),
  publishedAt: z.number(),
  createdAt: z.number(),
  updatedAt: z.number(),
  subjects: firestoreProductSubjectsSchema.optional().nullable(),
  summaryEmbedding: z.array(z.number()).optional(),
});

export const postBlurbResponseSchema = apiResponseMetadataBlurbResponseSchema;

export const postKeywordsResponseSchema =
  apiResponseMetadataKeywordResponseSchema;

export const searchQueryResponseHitSchema = z
  .record(z.union([z.unknown(), typesenseProductSchema]))
  .and(
    z.object({
      document: typesenseProductSchema,
      text_match: z.number().optional().nullable(),
      text_match_info: searchQueryResponseHitTextMatchInfoSchema
        .optional()
        .nullable(),
      vector_distance: z.number().optional().nullable(),
    }),
  );

export const searchQueryResponseSchema = z
  .record(z.union([z.unknown(), searchQueryResponseHitSchema]))
  .and(
    z.object({
      facet_counts: z.array(z.number()).optional().nullable(),
      found: z.number().optional().nullable(),
      out_of: z.number().optional().nullable(),
      page: z.number().optional().nullable(),
      search_cutoff: z.boolean().optional().nullable(),
      search_time_ms: z.number().optional().nullable(),
      request_params: searchQueryResponseRequestParamsSchema
        .optional()
        .nullable(),
      hits: z.array(searchQueryResponseHitSchema).optional(),
    }),
  );

export const apiResponseSearchQueryResponseSchema = z.object({
  success: z.boolean().optional(),
  data: searchQueryResponseSchema,
  code: z.number().optional(),
});

export const searchResponseSchema = apiResponseSearchQueryResponseSchema;
